<template>
  <v-chart :forceFit="true" :height="height" :data="dataSource" :padding="padding">
    <v-tooltip :showTitle="false" :itemTpl="itemTpl" />
    <v-polygon position="position" :color="color" :tooltip="tooltip" :vStyle="style" :label="label" />
  </v-chart>
</template>
<script>
// todo 下边的x值，角度再大一点，现在数量多的时候不易看清
// https://viserjs.gitee.io/demo.html
import { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
const DataSet = require('@antv/data-set')

export default {
  name: 'TreeChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      dataSource: []
      // adjust: [{
      //   type: 'dodge',
      //   marginRatio: 1 / 5
      // }]
    }
  },
  computed: {
    scale () {
      return [{
        dataKey: this.reportData['dimensions'][0],
        type: 'cat',
        tickCount: 10
      }]
    },
    metricsLabel() {
      const _unit = this.reportData.units[0]['unit']
      return {
        formatter(val) {
          return simplifyNumber(val) + _unit
        },
        textStyle: {
          fontSize: '12px',
          fill: '#888888'
        }
      }
    },
    metricsLabel1() {
      const _unit = this.reportData.units[1]['unit']
      return {
        formatter(val) {
          return simplifyNumber(val) + _unit
        },
        textStyle: {
          fontSize: '12px',
          fill: '#888888'
        }
      }
    },
    metricsTitle() {
      return {
        text: this.reportData.units[0]['metrics'].join('、')
      }
    },
    metricsTitle1() {
      return {
        text: this.reportData.units[1]['metrics'].join('、')
      }
    },
    position() {
      return this.reportData.dimensions[0] + '*categoryValue'
    },
    position1() {
      return this.reportData.dimensions[0] + '*categoryValue1'
    },
    color() {
      return ['categoryName', ['#1890FF', '#73C9E6', '#13C2C2', '#6CD9B3', '#2FC25B', '#9DD96C', '#FACC14', '#E6965C']]
    },
    color1() {
      return ['categoryName1', ['#F04864', '#D66BCA', '#8543E0', '#8E77ED', '#3436C7', '#737EE6', '#223273', '#7EA2E6']]
    },
    padding() {
      return [80, 100, 100, 100]
    }
  },
  mounted() {
    const dv = new DataSet.View().source(this.reportData.dataItems)
    dv.transform({
      type: 'fold',
      fields: this.reportData['units'][0]['metrics'],
      key: 'categoryName',
      value: 'categoryValue'
    })
    if (this.reportData['units'].length === 2) {
      dv.transform({
        type: 'fold',
        fields: this.reportData['units'][1]['metrics'],
        key: 'categoryName1',
        value: 'categoryValue1'
      })
    }
    this.dataSource = dv.rows
    this.chartRenderedDelay()
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
</style>
