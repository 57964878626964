<template>
  <v-chart
    v-if="ready"
    class="bar-table-chart"
    :forceFit="true"
    :height="height"
    :data="dataSource"
    :scale="scale"
    :padding="padding"
    :theme="theme"
    :key="key">
    <table border="1" class="bar-table" :style="{color: tableTitleColor, fontSize: labelFontSize + 'px'}">
      <tr v-for="(item, index) in groupRowsDataKeys" :key="index">
        <td ref="categoryTitle" class="first-td">{{ item.split('_')[1] }}</td>
        <td>
          <v-chart
            v-if="ready"
            :forceFit="true"
            :scale="scale"
            :height="barHeight(index, groupRowsData[item].length)"
            :data="groupRowsData[item]"
            :padding="index === groupRowsDataKeys.length - 1 ? facetPaddingFinal: facetPadding"
            :onClick="onClick"
            :theme="theme">
            <v-tooltip />
            <v-coord type="rect" direction="LT" />
            <v-axis :dataKey="xAxisName" position="left" :label="xAxisLabel" :grid="null"/>
            <v-axis :dataKey="yAxisName" :label="yAxisLabel" position="right" :grid="null"/>
            <v-bar :position="position" :size="barSize" :color="seriesName" :adjust="adjust"/>
          </v-chart>
        </td>
      </tr>
    </table>
  </v-chart>
</template>
<script>
import _, { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'

const DataSet = require('@antv/data-set')
export default {
  name: 'BarTableChart',
  components: {},
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    chartType: {
      type: String,
      default: 'Line'
    },
    xAxis: {
      type: String,
      default: ''
    },
    smartChartHeight: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      height: this.smartChartHeight,
      dataSource: [],
      xAxisName: '', // x轴键名
      yAxisName: '', // y轴键名
      tableCol1Name: '', // 第一列键名
      facetPadding: [5, 50, 0, 100],
      facetPaddingFinal: [-100, 90, 75, 100],
      padding: [0],
      adjust: [{
        type: 'dodge',
        marginRatio: 0.6
      }],
      xAxisTags: [],
      groupRowsData: {},
      groupRowsDataKeys: [],
      minYValue: 0,
      maxYValue: 0,
      metricsLen: 0,
      barSize: 14,
      lastClickMess: {},
      key: uuidv4()
    }
  },
  computed: {
    tableTitleColor () {
      if (this.theme.label) return this.theme.label.textStyle.fill
      else return '#2c3e50'
    },
    labelFontSize () {
      if (this.theme.label) return this.theme.label.textStyle.fontSize
      else return 12
    },
    xAxisLabel () {
      return {
        textStyle: {
          // fontSize: '0.75em'
        }
      }
    },
    yAxisLabel () {
      return {
        formatter(val) {
          if (val === '0') {
            return '  0'
          } else {
            return simplifyNumber(val)
          }
        },
        autoRotate: false,
        rotate: 45,
        textStyle: {
          fontSize: this.labelFontSize,
          textAlign: 'start'
        },
        offset: 15
      }
    },
    scale () {
      return [
        {
          dataKey: this.xAxisName,
          sync: false
        },
        {
          dataKey: this.yAxisName,
          max: this.maxYValue,
          min: this.minYValue,
          sync: true
        }
      ]
    },
    position () {
      return `${this.xAxisName}*${this.yAxisName}`
    }
  },
  methods: {
    getPaddingLeft () {
      let maxLength = 0
      this.xAxisTags.forEach((item) => { // 得到最长的一个标签的长度
        if (getStrFullLength(item) > maxLength) {
          maxLength = getStrFullLength(item)
        }
      })
      const wordWidth = this.labelFontSize
      this.facetPadding[3] = 8 + maxLength / 2 * wordWidth // 每个字12
      this.facetPaddingFinal[3] = 8 + maxLength / 2 * wordWidth // 每个字12
    },
    barHeight (index, dataLen) {
      if (index === this.groupRowsDataKeys.length - 1) {
        // if (this.metricsLen > 1) {
        //   this.facetPaddingFinal[2] = 100
        //   return 90
        // } else {
        this.facetPaddingFinal[2] = 48
        return 40
        // }
      } else {
        let height = 30
        if (this.dataSource.length * this.metricsLen < 10) {
          this.barSize = 14
          if (this.metricsLen > 1) {
            height = 18
            this.adjust[0].marginRatio = 0.6
          } else {
            height = 19
          }
        } else if (this.dataSource.length * this.metricsLen < 20) {
          this.barSize = 12
          if (this.metricsLen > 1) {
            height = 15
            this.adjust[0].marginRatio = 0.6
          } else {
            height = 16
          }
        } else {
          this.barSize = 10
          if (this.metricsLen > 1) {
            height = 12
            this.adjust[0].marginRatio = 0.7
          } else {
            height = 14
          }
        }
        return dataLen * height
      }
    },
    onClick(ev, chart) {
      if (this.lastClickMess.ev && this.lastClickMess.ev.toElement !== ev.toElement) {
        this.lastClickMess.chart.hideTooltip()
      }

      this.lastClickMess = {
        ev: ev,
        chart: chart
      }
    }
  },
  mounted() {
    this.dataSource = this.reportData['dataItems']
    this.metricsLen = this.reportData['metrics'].length
    if (this.xAxis) {
      this.xAxisName = this.xAxis
      this.tableCol1Name = this.reportData['dimensions'].find((item) => item !== this.xAxisName)
    } else {
      const _xAxes = this.reportData['dimensions'].slice(0, 2)
      const _xAxesLens = [_.uniqBy(this.dataSource, _xAxes[0]).length, _.uniqBy(this.dataSource, _xAxes[1]).length]
      if (_xAxesLens[0] > _xAxesLens[1]) _xAxes.reverse()
      this.tableCol1Name = _xAxes[0]
      this.xAxisName = _xAxes[1]
    }
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)

    const dv = new DataSet.View().source(this.dataSource)
    dv.transform({
      type: 'partition',
      groupBy: [this.tableCol1Name] // 分组
    })
    this.groupRowsData = dv.rows

    this.groupRowsDataKeys = [ ...Object.keys(this.groupRowsData) ]
    this.groupRowsData['lastRow'] = [{
      ...this.groupRowsData[this.groupRowsDataKeys[0]][0]
    }]
    this.groupRowsDataKeys.push('lastRow')
    this.seriesName = 'metricsName'
    this.yAxisName = 'metricsValue'
    this.groupRowsDataKeys.forEach((key, index) => {
      const dv2 = new DataSet.View().source(this.groupRowsData[key])
      dv2.transform({
        type: 'fold',
        fields: this.reportData['metrics'],
        key: this.seriesName,
        value: this.yAxisName
      })
      this.groupRowsData[key] = dv2.rows

      const maxValue = Math.max.apply(Math, this.groupRowsData[key].map(x => x[this.yAxisName]))
      const minValue = Math.min.apply(Math, this.groupRowsData[key].map(x => x[this.yAxisName]))
      if (this.maxYValue < maxValue) {
        this.maxYValue = maxValue
        console.log('maxYValue', this.maxYValue)
      }
      if (this.minYValue > minValue) this.minYValue = minValue
    })
    this.$nextTick(() => {
      const categoryTitleArr = this.$refs['categoryTitle']
      const categoryTitleHeightArr = categoryTitleArr.map(item => item.offsetHeight)
      const _height = categoryTitleHeightArr.reduce(function(prev, cur, index, array) {
        return prev + cur
      }) + 10 // 表格开头有10px
      this.height = _height > this.smartChartHeight ? _height : this.smartChartHeight
    })

    this.getPaddingLeft()
    this.ready = true
    this.chartRenderedDelay()
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created() {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.bar-table-chart {
  position: relative;
  .bar-table {
    width: 100%;
    position: absolute;
    top: 10px;
    border: none;
    // border-color: #c4c4c4;
    z-index: 2;
    tr:first-child {
      td {
        border-top-style: solid !important;
        border-top-color: #eeeeee !important;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
    tr {
      height: 100%;
      .first-td {
        text-align: center;
        border-left: none;
        border-right: none;
        border-top: none;
        border-width: 1px;
        width: 20%;
        font-size: 0.75rem;
      }
      td:last-child {
        border-right: none;
        border-left: none;
        border-top: none;
        border-width: 1px;
        font-size: 0.75rem;
      }
    }
  }
}
</style>
