<template>
  <div class="circle_packing" :style="{position: 'relative', top: height / 2 - thisHeight / 2 + 'px'}">
    <v-chart
      :height="thisHeight"
      :width="thisHeight"
      :data="data"
      :padding="0"
      :scale="scale">
      <v-tooltip :showTitle="false" :crosshairs="false" :itemTpl="itemTpl"/>
      <v-axis dataKey="x" :show="false"/>
      <v-axis dataKey="y" :show="false"/>
      <v-point
        position="x*y"
        shape="circle"
        :tooltip="tooltip"
        :size="size"
        :color="color"
        :vStyle="style"
        :label="label" />
    </v-chart>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { getStrFullLength } from '@/utils/utils'
import { simplifyNumber } from '@/utils/numberUtil'
const DataSet = require('@antv/data-set')
export default {
  name: 'BubbleChart',
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // data: '',
      xAxisName: '',
      yAxisName: '',
      xAxisName2: '',
      // yAxisName: '',
      dataSource: {
        name: 'root',
        children: []
      },
      thisHeight: Math.min(this.$parent.$parent.$el.offsetWidth, this.height),
      // color: ['name', ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef', '#1890FF', '#73C9E6', '#13C2C2', '#6CD9B3', '#ded8fe', '#9DD96C', '#be9c236b', '#d6e98a', '#e9d48a']],
      data: [],
      scale: [{
        dataKey: 'x',
        min: 0,
        max: 1
      }, {
        dataKey: 'y',
        min: 0,
        max: 1
      }],
      // padding: [ 60, 0, 40, 100 ],
      label: [
        'name*r', (val, r) => {
          const width = r * this.thisHeight * 2
          // console.log('val', val)
          // console.log('valr', r)
          return {
            offset: 0,
            type: 'treemap', // 好像没有用
            textStyle: (text, item) => {
              // console.log('text.length', getStrFullLength(text))
              // console.log('width', width)
              if (item.point.name === 'root' && item.point.value === 0) {
                return {
                  textBaseline: 'middle',
                  fontSize: this.theme['label'] ? this.labelFontSize : '0.75em',
                  textAlign: 'center',
                  fill: this.axisLabelColor
                }
              } else {
                if (item.point.hasChildren || getStrFullLength(text) > width / 4) {
                  return {
                    opacity: 0 // 隐藏溢出的标签
                  }
                }
                return {
                  textBaseline: 'middle',
                  fontSize: this.labelFontSize,
                  textAlign: 'center',
                  fill: this.axisLabelColor
                }
              }
            },
            formatter: function(text, item, index) {
              if (item.point.name === 'root' && item.point.value === 0) {
                return '暂无数据'
              } else {
                return text
              }
            }
          }
        }
      ],
      size: ['r', r => r * this.thisHeight],
      style: ['depth', {
        stroke: '#e6f7ff',
        opacity: 1
      }],
      colors: ['#0073de', '#1f77b4', '#629fc9', '#94bedb', '#73C9E6', '#13C2C2', '#6CD9B3', '#ded8fe', '#9DD96C', '#be9c236b', '#d6e98a', '#e9d48a']
      // color: ['r', 'rgb(252, 253, 191)-rgb(231, 82, 99)-rgb(183, 55, 121)']
    }
  },
  computed: {
    labelFontSize () {
      if (this.theme.label) return this.theme.label.textStyle.fontSize
      else return 12
    },
    axisLabelColor () {
      if (this.theme.label) return this.theme.label.textStyle.fill
      else return '#686868'
    },
    color () {
      // return ['name', this.theme.colors_tree_buble || this.colors]
      return ['name', this.theme.colors_24 || this.colors]
    },
    tooltip() {
      return ['name*value', (name, value) => {
        const count = simplifyNumber(value)
        const text = this.yAxisName
        const unit = this.reportData.units[0].unit
        return {
          name,
          count,
          text,
          unit
        }
      }]
    },
    itemTpl() {
      return `<li data-index={index} style="text-align:left;">
                <span style="background-color:{color};" class="g2-tooltip-marker"></span>
                {name}
                <br/>
                <span style="padding-left: 16px">{text}：{count} {unit}</span><br/>
              </li>`
    }
  },
  methods: {
  },
  mounted() {
    const _this = this
    window.onresize = function () {
      _this.thisHeight = Math.min(_this.$parent.$parent.$el.offsetWidth, _this.height)
    }

    this.xAxisName = this.xAxis || this.reportData.dimensions[0]
    this.yAxisName = this.reportData.metrics[0]
    if (this.reportData.dimensions.length > 1) {
      this.xAxisName2 = this.xAxisName === this.reportData.dimensions[1] ? this.reportData.dimensions[0] : this.reportData.dimensions[1]
    }

    if (this.reportData.dimensions.length > 1) {
      this.reportData.dataItems.forEach(item => {
        this.dataSource.children.push({
          value: Math.abs(item[this.yAxisName]),
          name: item[this.xAxisName] + ' ' + item[this.xAxisName2],
          ...item
        })
      })
    } else {
      this.reportData.dataItems.forEach(item => {
        this.dataSource.children.push({
          value: Math.abs(item[this.yAxisName]),
          name: item[this.xAxisName],
          ...item
        })
      })
    }

    // this.dataSource.children = [...this.reportData.dataItems]
    // console.log('bubble', this.dataSource)
    const dv = new DataSet.View().source(this.dataSource, {
      type: 'hierarchy'
    })
    dv.transform({
      type: 'hierarchy.circle-packing'
    })

    this.data = dv.getAllNodes().map(node => {
      // console.log('node', node)
      if (node.depth === 0 || node.value === 0) {
        node.r = 0
      }
      if (node.r && node.r < 0.01) {
        node.r = 0.01
      }
      return {
        hasChildren: !!(node.data.children && node.data.children.length),
        name: node.data.name.split(/(?=[A-Z][^A-Z])/g).join('\n'),
        value: node.value,
        depth: node.depth,
        x: node.x,
        y: node.y,
        r: node.r
      }
    })
    this.chartRenderedDelay()
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>
<style lang="less">
.circle_packing {
  padding: 0;
  text-align: center;
}
</style>
