<template>
  <v-chart
    v-if="ready"
    class="multi-facet-chart"
    :forceFit="true"
    :height="canvasHeight"
    :data="dataSource"
    :scale="scale"
    :theme="theme"
    :key="key"
    :padding="padding">
    <table border="1" cellspacing="0" class="multi-facet--table">
      <template v-for="(key1, index1) in tableCol1Keys">
        <template v-for="(key2, index2) in [...Object.keys(groupRowsData[key1])]">
          <tr :key="index1 + '_' + index2">
            <template v-if="key1 === 'lastRow'">
              <template v-if="index2 === 0">
                <td ref="facetTitle" class="first-td"></td>
                <td class="second-td"></td>
                <td ref="chartTd" class="third-td">
                  <v-chart
                    v-if="ready"
                    :forceFit="true"
                    :data="groupRowsData[key1][key2]"
                    :height="trBottomHeight"
                    :scale="scale"
                    :key="key + facetPaddingFinal[2]"
                    :padding="facetPaddingFinal"
                    :onClick="onClick"
                    :theme="theme">
                    <v-tooltip />
                    <v-legend
                      :offsetY="45"
                      :clickable="false"
                      :itemFormatter="itemFormatter"
                      :layout="chartType === 'LineColumn' && legendRowNum > 1? 'vertical': 'horizontal'"/>
                    <v-axis :dataKey="xAxisName" :label="label" />
                    <v-axis :dataKey="yAxisName1" :label="yAxisLabel"/>
                    <template v-if="chartType === 'Column'">
                      <v-stack-bar v-if="seriesName1 && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                      <v-bar v-else-if="seriesName1 && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
                      <v-bar v-else :position="xAxisName + '*' + yAxisName1" />
                    </template>
                    <template v-if="chartType === 'Line'">
                      <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                    </template>
                    <template v-if="chartType === 'Area'">
                      <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" adjust="stack" />
                      <v-stack-area :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                    </template>
                    <template v-if="chartType === 'LineColumn'">
                      <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
                      <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" />
                    </template>
                  </v-chart>
                </td>
              </template>
            </template>
            <template v-else>
              <td v-if="index2 === 0" :rowspan="[...Object.keys(groupRowsData[key1])].length" ref="facetTitle" class="first-td">{{ key1.split('_')[1] }}</td>
              <td class="second-td">{{ key2.split('_')[1] }}</td>
              <td class="third-td">
                <v-chart
                  v-if="ready"
                  :forceFit="true"
                  :data="groupRowsData[key1][key2]"
                  :height="trHeight"
                  :scale="scale"
                  :key="key"
                  :padding="facetPadding"
                  :onClick="onClick"
                  :theme="theme">
                  <v-tooltip />
                  <v-axis :dataKey="xAxisName" :label="label" />
                  <v-axis :dataKey="yAxisName1" :label="yAxisLabel"/>
                  <template v-if="chartType === 'Column'">
                    <v-stack-bar v-if="seriesName1 && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                    <v-bar v-else-if="seriesName1 && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
                    <v-bar v-else :position="xAxisName + '*' + yAxisName1" />
                  </template>
                  <template v-if="chartType === 'Line'">
                    <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                  </template>
                  <template v-if="chartType === 'Area'">
                    <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" adjust="stack" />
                    <v-stack-area :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                  </template>
                  <template v-if="chartType === 'LineColumn'">
                    <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
                    <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" />
                  </template>
                </v-chart>
              </td>
            </template>
          </tr>
        </template>
      </template>
    </table>
  </v-chart>
</template>
<script>
import _, { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'

const DataSet = require('@antv/data-set')
export default {
  name: 'MultiFacetChart',
  components: {},
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    chartType: {
      type: String,
      default: 'Line'
    },
    seriesType: {
      type: String,
      default: 'Group'
    },
    xAxis: {
      type: String,
      default: ''
    },
    smartChartHeight: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      canvasHeight: this.smartChartHeight,
      dataSource: [],
      xAxisName: '', // x轴键名
      yAxisName1: '', // y轴键名
      yAxisName2: '', // y轴键名
      seriesName1: '',
      seriesName2: '', // 系列名
      tableCol1Name: '', // 第一列键名
      tableCol2Name: '', // 第二列键名
      facetPadding: [15, 20, 5, 45],
      facetPaddingFinal: [-4, 20, 143, 45],
      padding: [0],
      adjust: [{
        type: 'dodge',
        marginRatio: 1 / 32
      }],
      xAxisTags: [],
      xAxisLen: 0,
      groupRowsData: {},
      tableCol1Keys: [],
      // tableCol2Keys: [],
      minYValue1: 0,
      maxYValue1: 0,
      minYValue2: 0,
      maxYValue2: 0,
      metricsLen: 0,
      barSize: 14,
      lastClickMess: {},
      maxXtagLength: 0,
      trHeight: 100,
      trBottomHeight: 30,
      legendRowNum: 0,
      key: uuidv4()
    }
  },
  computed: {
    label () {
      return {
        autoRotate: false,
        rotate: this.maxXtagLength > 12 ? 70 : 45,
        textStyle: {
          fontSize: '0.75em',
          textAlign: 'start'
        },
        offset: 15
        // offset: 45
      }
    },
    yAxisLabel () {
      return {
        formatter(val) {
          if (val === '0') {
            return '0'
          } else {
            return simplifyNumber(val)
          }
        },
        textStyle: {
          // fontSize: '1.75em',
          // fill: '#888888'
        }
      }
      // return {
      //   formatter(val) {
      //     if (val === '0') {
      //       return '0'
      //     } else {
      //       return simplifyNumber(val)
      //     }
      //   },
      //   textStyle: {
      //     // fontSize: '1.75em',
      //     // fill: '#888888'
      //   }
      // }
    },
    // yAxisTitle () {
    //   const _unit = this.reportData.units[0]['unit']
    //   return {
    //     text: this.reportData['metrics'].join('、') + `（${_unit}）`,
    //     position: 'center',
    //     offset: 35,
    //     textStyle: {
    //       // fontSize: 12,
    //       fill: '#888888'
    //     }
    //   }
    // },
    // yAxisTitle1 () {
    //   return {
    //     text: this.yAxisTitleText1,
    //     textStyle: {
    //       textAlign: 'center'
    //     }
    //   }
    // },
    scale () {
      return [
        {
          dataKey: this.xAxisName,
          tickCount: this.xAxisLen > 6 ? 9 : this.xAxisLen,
          sync: false
        },
        {
          dataKey: this.yAxisName1,
          tickCount: 5,
          max: this.maxYValue1,
          min: this.minYValue1 < 0 ? this.minYValue1 : 0,
          sync: true
        },
        {
          dataKey: this.yAxisName2,
          max: this.maxYValue2,
          min: this.minYValue2 < 0 ? this.minYValue2 : 0,
          sync: true
        }
      ]
    }
  },
  mounted() {
    this.dataSource = this.reportData['dataItems']
    this.metricsLen = this.reportData['metrics'].length
    if (this.xAxis) {
      this.xAxisName = this.xAxis
      const _xAxes = this.reportData['dimensions'].filter(item => item !== this.xAxis)
      const _xAxesLens = [_.uniqBy(this.dataSource, _xAxes[0]).length, _.uniqBy(this.dataSource, _xAxes[1]).length]
      if (_xAxesLens[0] > _xAxesLens[1]) _xAxes.reverse()
      this.tableCol1Name = _xAxes[0]
      this.tableCol2Name = _xAxes[1]
    } else {
      const _xAxes = this.reportData['dimensions'].slice(0, 3)
      const _xAxesLens = [_.uniqBy(this.dataSource, _xAxes[0]).length, _.uniqBy(this.dataSource, _xAxes[1]).length, _.uniqBy(this.dataSource, _xAxes[2]).length]
      for (let i = 0; i < _xAxesLens.length - 1; i++) { //
        let minIndex = i
        for (let j = i + 1; j < _xAxesLens.length; j++) {
          if (_xAxesLens[i] > _xAxesLens[minIndex]) {
            minIndex = j
          }
        }
        let temp = _xAxesLens[i]
        _xAxesLens[i] = _xAxesLens[minIndex]
        _xAxesLens[minIndex] = temp
        temp = _xAxes[i]
        _xAxes[i] = _xAxes[minIndex]
        _xAxes[minIndex] = temp
      }
      this.tableCol1Name = _xAxes[0]
      this.tableCol2Name = _xAxes[1]
      this.xAxisName = _xAxes[2]
    }
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    this.xAxisLen = this.xAxisTags.length
    // this.tableCol2Keys = getDiffValue(this.reportData.dataItems, this.tableCol2Name)

    const dv = new DataSet.View().source(this.dataSource)
    // if (this.metricsLen > 1) {

    dv.transform({
      type: 'fold',
      fields: this.reportData.units[0]['metrics'],
      key: 'metricsName1',
      value: 'metricsValue1'
    })
    this.seriesName1 = 'metricsName1'
    this.yAxisName1 = 'metricsValue1'
    if (this.reportData.units.length === 2) {
      dv.transform({
        type: 'fold',
        fields: this.reportData.units[1]['metrics'],
        key: 'metricsName2',
        value: 'metricsValue2'
      })
      this.seriesName2 = 'metricsName2'
      this.yAxisName2 = 'metricsValue2'
    }

    this.dataSource = dv.rows.map(item => {
      if (isNaN(item[this.yAxisName1]) || typeof item[this.yAxisName1] !== 'number') item[this.yAxisName1] = 0
      if (isNaN(item[this.yAxisName2]) || typeof item[this.yAxisName2] !== 'number') item[this.yAxisName2] = 0
      return item
    })
    // } else {
    //   const _unit1 = this.reportData.units[0]['unit']
    //   const _unitEnclosed1 = _unit1 ? `（${_unit1}）` : ''
    //   this.yAxisName1 = this.reportData['metrics'][0]
    //   this.yAxisTitleText1 = this.reportData.metrics[0] + _unitEnclosed1
    // this.axiasTitleLen = getStrFullLength(this.metricsTitle.text)
    // }
    dv.transform({
      type: 'partition',
      groupBy: [this.tableCol1Name] // 分组
    })
    this.tableCol1Keys = [...Object.keys(dv.rows)]

    this.tableCol1Keys.forEach((key, index) => {
      const dv1 = new DataSet.View().source(dv.rows[key])
      dv1.transform({
        type: 'partition',
        groupBy: [this.tableCol2Name] // 分组
      })
      this.groupRowsData[key] = dv1.rows

      if (index === 0) {
        this.groupRowsData['lastRow'] = this.groupRowsData[key]
        this.tableCol1Keys.push('lastRow')
      }
    })

    const AreaValueArray1 = this.dataSource.map(x => x[this.yAxisName1])
    AreaValueArray1.sort(function (a, b) {
      return a - b
    })
    console.log('AreaValueArray1', AreaValueArray1)
    const AreaValueArrayLen1 = AreaValueArray1.length
    if (this.chartType === 'Area' || (this.chartType === 'Column' && this.seriesType === 'stack')) {
      this.minYValue1 = AreaValueArray1[0] + AreaValueArray1[1]
      this.maxYValue1 = AreaValueArray1[AreaValueArrayLen1 - 1] + AreaValueArray1[AreaValueArrayLen1 - 2]
    } else {
      this.minYValue1 = AreaValueArray1[0]
      this.maxYValue1 = AreaValueArray1[AreaValueArrayLen1 - 1]
    }
    if (this.yAxisName2) {
      const AreaValueArray2 = this.dataSource.map(x => x[this.yAxisName2])
      AreaValueArray2.sort(function (a, b) {
        return a - b
      })
      // console.log('AreaValueArray2', AreaValueArray2)
      const AreaValueArrayLen2 = AreaValueArray2.length
      if (this.chartType === 'Area' || (this.chartType === 'Column' && this.seriesType === 'stack')) {
        this.minYValue2 = AreaValueArray2[0] + AreaValueArray2[1]
        this.maxYValue2 = AreaValueArray2[AreaValueArrayLen2 - 1] + AreaValueArray2[AreaValueArrayLen2 - 2]
      } else {
        this.minYValue2 = AreaValueArray2[0]
        this.maxYValue2 = AreaValueArray2[AreaValueArrayLen2 - 1]
      }
    }
    this.getPaddingBottom()
    this.getPaddingRight()
    this.getChartHeight()
    this.ready = true
    this.chartRenderedDelay()
  },
  methods: {
    itemFormatter (val) {
      const valItem = this.reportData['units'].find(item => item['metrics'].find(metr => metr === val))
      console.log('1', val)
      // return val
      return valItem.unit ? val + '(' + valItem.unit + ')' : val
    },
    getChartHeight () {
      this.$nextTick(() => { // 计算高度
        // console.log('facetTitleHeightArr', facetTitleHeightArr)
        // console.log('canvasHeight', _height)
        // console.log('trHeight', this.trHeight)

        const facetTitleArr = this.$refs['facetTitle']
        console.log('facetTitleArr', facetTitleArr)
        const facetTitleHeightArr = facetTitleArr.map(item => item.offsetHeight)

        console.log('facetTitleHeightArr', facetTitleHeightArr)
        const _height = facetTitleHeightArr.reduce(function(prev, cur, index, array) {
          return prev + cur
        }) + 10 // 表格开头有10px
        console.log('canvasHeight', _height)
        if (_height > this.smartChartHeight) {
          this.canvasHeight = _height
        } else {
          this.canvasHeight = this.smartChartHeight
          console.log('(this.canvasHeight - facetTitleHeightArr[facetTitleHeightArr.length - 1]) / this.facetLen - 7 * this.facetLen - 5', (this.canvasHeight - facetTitleHeightArr[facetTitleHeightArr.length - 1]) / this.facetLen - 7 * this.facetLen - 5)
          this.trHeight = Math.max(this.trHeight, (this.canvasHeight - facetTitleHeightArr[facetTitleHeightArr.length - 1]) / this.facetLen - 7 * this.facetLen - 5) // 减去每行比图表多出的空间,判断是否因为title过长需要加长显示
        }
      })
    },
    getMaxXTagLength () {
      this.maxXtagLength = 0
      // let wordLength = 0
      this.xAxisTags.forEach((item) => {
        const len = getStrFullLength(item)
        if (len > this.maxXtagLength) {
          this.maxXtagLength = len
        }
      })
    },
    addLegendHeight () {
      this.$nextTick(() => {
        const _wrapperRect = this.$refs.chartTd[0].getBoundingClientRect()
        // console.log('this', this)
        // console.log('_wrapperRect', _wrapperRect)
        const bodyWidthInner = _wrapperRect.width - 2
        const singleLineWordNum = Math.floor(bodyWidthInner / 12) // 设一个字接近12，得到一排可以容纳的字数
        // const legendSpace = // 刚好不显示图例的位置
        // console.log('singleLineWordNum', singleLineWordNum)
        let maxLegendLength = 0
        // this.padding[2] = legendSpace // 还原没有图例时的高度
        const seriesArray = []
        this.reportData['units'].forEach(item => {
          item['metrics'].forEach(metr => {
            if (item['unit']) {
              seriesArray.push(metr + '(' + item['unit'] + ')')
            } else {
              seriesArray.push(metr)
            }
          })
        })
        // const seriesArray = getDiffValue(this.dataSource, this.seriesName1)
        // console.log('seriesArray', seriesArray)
        seriesArray.forEach(serie => {
          maxLegendLength += getStrFullLength(serie) + 5// 加5表示图标和空隙占5个字符的位置
        })
        this.legendRowNum = Math.ceil(maxLegendLength / (singleLineWordNum * 2))
        let legendHeight = 0
        if (this.chartType === 'LineColumn' && this.legendRowNum > 1) {
          let maxLengendLen = 0
          this.reportData['units'].forEach(item => {
            const metriceLen = item['metrics'].length
            if (maxLengendLen < metriceLen) {
              maxLengendLen = metriceLen
            }
          })
          // console.log('maxLengendLen', maxLengendLen)
          legendHeight = 10 + maxLengendLen * 22 // 图例行数*图例高度
        } else {
          legendHeight = 10 + this.legendRowNum * 22 // 图例行数*图例高度
          // console.log('Math.ceil(maxLegendLength / (singleLineWordNum * 2))', Math.ceil(maxLegendLength / (singleLineWordNum * 2)))
        }
        // console.log('maxLegendLength', maxLegendLength)
        // const legendHeight = 10 + Math.ceil(maxLegendLength / (singleLineWordNum * 2)) * 22 // 图例行数*图例高度
        // console.log('Math.ceil(maxLegendLength / (singleLineWordNum * 2))', Math.ceil(maxLegendLength / (singleLineWordNum * 2)))
        this.trBottomHeight += legendHeight // legend偏移位置45
        this.facetPaddingFinal[2] = this.trBottomHeight + 5 // 图例前的空白位置+图例行数*图例高度
        // console.log('this.trBottomHeight', this.trBottomHeight)
        this.getChartHeight()
      })
    },
    getPaddingBottom () {
      this.getMaxXTagLength()
      if (this.maxXtagLength > 16) {
        this.$nextTick(() => {
          let XTagMaxHeight = 0
          const xTagHtmlArr = document.getElementsByClassName('multi-label-html-text')
          console.log('xTagHtmlArr', xTagHtmlArr)
          xTagHtmlArr.forEach(item => {
            console.log('item.offsetHeight', item.offsetHeight)
            XTagMaxHeight = item.offsetHeight > XTagMaxHeight ? item.offsetHeight : XTagMaxHeight
            console.log('XTagMaxHeight', XTagMaxHeight)
          })
          console.log('XTagMaxHeight', XTagMaxHeight)
          this.facetPaddingFinal[2] = XTagMaxHeight + 5
          this.trBottomHeight = XTagMaxHeight
          if (this.seriesName1) {
            this.addLegendHeight()
          }
          // this.$forceUpdate()
        })
      } else {
        const wordWidth = 6
        // const wordWidth = this.maxXtagLength > 12 ? 5.5 : 8 // 每个字节5.5长度，maxLength为字节长度
        if (this.maxXtagLength > 12) { // 倾斜时会有重叠情况，45度时，重叠一半，70度时，按8字节重复1字节算, +10为尾部空隙
          this.trBottomHeight = 13 + (this.maxXtagLength - Math.floor(this.maxXtagLength / 8)) * wordWidth
        } else {
          this.trBottomHeight = 13 + (this.maxXtagLength / 2 + 2) * wordWidth
        }
        // this.trBottomHeight = this.maxXtagLength * wordWidth// 每个字11
        this.facetPaddingFinal[2] = 5 + this.trBottomHeight
        console.log('getPaddingBottom', this.facetPaddingFinal[2])
        // this.padding[2] = this.maxXtagLength * wordWidth
        if (this.seriesName1) {
          this.addLegendHeight()
        }
      }
      // this.padding[2] = 11 + this.maxXtagLength * 9 // 单个图时，每个字节9
    },
    getPaddingRight () {
      const len = this.xAxisTags.length
      let i = len - 2
      let tag = 1
      let maxLength = getStrFullLength(this.xAxisTags[len - 1])
      const wordWidth = this.maxXtagLength > 12 ? 2.5 : 6 // 每个字节2.5宽度，maxLength为字节长度
      const wordNum = this.maxXtagLength > 12 ? 5 : 2
      while (i >= 0) {
        // 5表示当前一个比后一个多5个字以上时，才会导致前一个标签为到右边最远的标签
        // 将离右边最近的标签与其之前的标签对比，得到往右延伸更远的标签
        if (maxLength + wordNum * tag < getStrFullLength(this.xAxisTags[i])) {
          maxLength = getStrFullLength(this.xAxisTags[i])
          tag = 1
        }
        i--
        tag++
      }
      console.log('maxLength * wordWidth - 6', maxLength * wordWidth - 6)
      this.facetPaddingFinal[1] = Math.max(this.facetPaddingFinal[1], maxLength * wordWidth - 6) // 减去为0时已经显示的部分
      this.facetPadding[1] = Math.max(this.facetPadding[1], maxLength * wordWidth - 6)
    },
    onClick(ev, chart) {
      if (this.lastClickMess.ev && this.lastClickMess.ev.toElement !== ev.toElement) {
        this.lastClickMess.chart.hideTooltip()
      }

      this.lastClickMess = {
        ev: ev,
        chart: chart
      }
    }
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created() {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.multi-facet-chart {
  position: relative;
  .multi-facet--table {
    width: 100%;
    position: absolute;
    top: 10px;
    border: none;
    // border-color: #c4c4c4;
    z-index: 2;
    tr:first-child {
      td {
        border-top-style: solid !important;
        border-top-color: #eeeeee !important;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
    tr {
      height: 100%;
      .first-td {
        text-align: center;
        border-left: none;
        // border-right: none;
        border-top: none;
        border-width: 1px;
        width: 16%;
        font-size: 0.75rem;
        padding: 0 2px;
      }
      .second-td {
        // border-right: none;
        text-align: center;
        border-left: none;
        border-top: none;
        border-width: 1px;
        width: 14%;
        font-size: 0.75rem;
      }
      .third-td {
        border-right: none;
        border-left: none;
        border-top: none;
        border-width: 1px;
        font-size: 0.75rem;
      }
    }
  }
}
</style>
