<template>
  <van-overlay :show="showShare" @click="showShare = false" z-index="10">
    <div class="wx-share-tips-wrapper" :style="{'padding-top': statusBarHeight + 60 + 'px'}">
      <div class="arrow"><img src="@/assets/img/user/arrow-share.png" alt=""></div>
      <div class="text">
        请点击右上角<br/>
        将它发送给指定的朋友<br/>
        或者分享到朋友圈
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'WxShareTips',
  data () {
    return {
      showShare: false
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight
    })
  },
  methods: {
    share (params) {
      const shareData = {
        title: params['title'],
        link: params['thumbUrl'],
        imgUrl: params['thumbUrl']
      }
      this.updateAppShareData(shareData)
      this.showShare = true
    },
    updateAppShareData (shareData) {
      this.$wechat.updateAppMessageShareData(shareData)
      this.$wechat.updateTimelineShareData(shareData)
    }
  }
}
</script>
<style lang="less">
.wx-share-tips-wrapper {
  padding: 40px;
  height: 100vh;

  .arrow {
    text-align: right;

    img {
      width: 80px;
    }
  }

  .text {
    color: #fff;
    text-align: center;
    font-size: 1.6em;
    line-height: 2em;
  }
}
</style>
