<template>
  <div class="tree-diagram-chart">
    <v-chart
      :forceFit="true"
      :height="height"
      :data="data"
      :scale="scale"
      :key="key"
      :padding="[10, 10, 10, 10]"
      :theme="theme">
      <v-tooltip :showTitle="false" :itemTpl="itemTpl" />
      <v-polygon position="x*y" :color="color" :tooltip="tooltip" :vStyle="style" :label="label" />
    </v-chart>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
import { v4 as uuidv4 } from 'uuid'
const DataSet = require('@antv/data-set')

export default {
  name: 'TreeDiagramChart',
  //   components: {
  //   },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      data: '',
      xAxisName: '',
      yAxisName: '',
      sourceData: {
        name: 'root',
        children: []
      },
      color: ['name'],
      key: uuidv4()
      // color: ['name', ['#1f77b4', '#629fc9', '#94bedb', '#c9e0ef', '#1890FF', '#73C9E6', '#13C2C2', '#6CD9B3', '#ded8fe', '#9DD96C', '#be9c236b', '#d6e98a', '#e9d48a']]
      // color: ['name', ['#1890FF', '#73C9E6', '#13C2C2', '#6CD9B3', '#2FC25B', '#afa3f2', '#ded8fe', '#9DD96C', '#be9c236b', '#d6e98a', '#e9d48a']]
    }
  },
  computed: {
    labelFontSize () {
      if (this.theme.label) return this.theme.label.textStyle.fontSize
      else return 12
    },
    axisLabelColor () {
      if (this.theme.label) return this.theme.label.textStyle.fill
      else return '#686868'
    },
    scale() {
      return [
        {
          dataKey: 'value',
          nice: true,
          min: 0,
          max: 1
        }, {
          dataKey: 'x',
          max: 1,
          min: 0
        }, {
          dataKey: 'y',
          max: 1,
          min: 0
        }
      ]
    },
    label() {
      return ['name', {
        offset: 0,
        textStyle: {
          fontSize: this.labelFontSize,
          textBaseline: 'middle',
          fill: this.axisLabelColor
        },
        formatter(val) {
          if (val !== 'root undefined' && val !== 'root') {
            return val
          }
        },
        type: 'treemap'
      }]
    },
    tooltip() {
      // return ['name*value', (name, count) => {
      //   return {
      //     name,
      //     count
      //   }
      // }]
      return ['name*value*text', (name, value, text) => {
        const count = simplifyNumber(value)
        return {
          name,
          count,
          text
        }
      }]
    },
    itemTpl() {
      return `<li data-index={index}>
                <span style="background-color:{color};" class="g2-tooltip-marker"></span>
                {name}<br/>
                <span style="padding-left: 16px">{text}：{count}</span><br/>
              </li>`
    },
    style() {
      return {
        lineWidth: 1,
        stroke: '#fff'
      }
    }
  },
  mounted() {
    const dataItems = this.reportData.dataItems.filter(item => item[this.reportData.units[0].metrics] > 0)
    const dv2 = new DataSet.View().source(dataItems)
    dv2.transform({
      type: 'fold',
      fields: this.reportData.units[0].metrics,
      key: 'categoryName',
      value: 'categoryValue'
    })
    // console.log('dev2', dv2)
    if (this.reportData.units.length === 2) {
      dv2.transform({
        type: 'fold',
        fields: this.reportData.units[1].metrics,
        key: 'categoryName1',
        value: 'categoryValue1'
      })
    }
    console.log('dev22', dv2.rows)
    // console.log('xAxis', this.xAxis)
    this.xAxisName = this.xAxis || this.reportData.dimensions[0]
    this.yAxisName = this.reportData.metrics[0]
    const dv1 = new DataSet.View().source(dataItems)
    dv1.transform({
      type: 'rename',
      map: {
        [this.xAxisName]: 'name',
        [this.yAxisName]: 'value'
      }
    })
    dv1.rows.map(item => this.sourceData.children.push(item))
    // console.log('dv', dv1)
    const dv = new DataSet.View().source(this.sourceData, {
      type: 'hierarchy'
    })
    dv.transform({
      field: 'value',
      type: 'hierarchy.treemap',
      tile: 'treemapResquarify', // 布局类型
      as: ['x', 'y']
    })
    if (this.reportData.dimensions.length === 1) {
      this.data = dv.getAllNodes().map((node) => ({
        ...node,
        name: node.data.name,
        value: node.data.value,
        text: this.yAxisName
      }))
    } else {
      const dimensLen = this.reportData.dimensions.length
      this.data = dv.getAllNodes().map((node) => {
        let descripts = ' '
        for (let i = 0; i < dimensLen; i++) { // 如果有多个维度，加一起显示
          if (this.reportData.dimensions[i] !== this.xAxisName) {
            descripts += node.data[this.reportData.dimensions[i]]
          }
        }
        return {
          ...node,
          name: node.data.name + descripts,
          value: node.data.value,
          text: this.yAxisName
        }
      }
      )
      // this.data = dv.getAllNodes().map((node) => ({
      //   ...node,
      //   name: node.data.name + ' ' + node.data[this.reportData['dimensions'][0]],
      //   value: node.data.value,
      //   text: this.yAxisName
      // }))
    }
    this.chartRenderedDelay()
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
// .tree-diagram-chart {
// }
</style>
