/**
 * @fileOverview G2 3.0 default theme
 * @author sima.zhang
 */
import { repeatArray } from '@/utils/utils'
import _ from 'lodash'

const DEFAULT_COLOR = '#1890FF'
const COLOR_PLATE_8 = [
  '#1890FF',
  '#2FC25B',
  '#FACC14',
  '#223273',
  '#8543E0',
  '#13C2C2',
  '#3436C7',
  '#F04864'
]
const COLOR_PLATE_16 = [
  '#1890FF',
  '#41D9C7',
  '#2FC25B',
  '#FACC14',
  '#E6965C',
  '#223273',
  '#7564CC',
  '#8543E0',
  '#5C8EE6',
  '#13C2C2',
  '#5CA3E6',
  '#3436C7',
  '#B381E6',
  '#F04864',
  '#D598D9'
]
const COLOR_PLATE_24 = [
  '#1890FF',
  '#66B5FF',
  '#41D9C7',
  '#2FC25B',
  '#6EDB8F',
  '#9AE65C',
  '#FACC14',
  '#E6965C',
  '#57AD71',
  '#223273',
  '#738AE6',
  '#7564CC',
  '#8543E0',
  '#A877ED',
  '#5C8EE6',
  '#13C2C2',
  '#70E0E0',
  '#5CA3E6',
  '#3436C7',
  '#8082FF',
  '#DD81E6',
  '#F04864',
  '#FA7D92',
  '#D598D9'
]
const COLOR_PIE = [
  '#1890FF',
  '#13C2C2',
  '#2FC25B',
  '#FACC14',
  '#F04864',
  '#8543E0',
  '#3436C7',
  '#223273'
]
const COLOR_PIE_16 = [
  '#1890FF',
  '#73C9E6',
  '#13C2C2',
  '#6CD9B3',
  '#2FC25B',
  '#9DD96C',
  '#FACC14',
  '#E6965C',
  '#F04864',
  '#D66BCA',
  '#8543E0',
  '#8E77ED',
  '#3436C7',
  '#737EE6',
  '#223273',
  '#7EA2E6'
]

const FONT_FAMILY = '"-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto,"Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",SimSun, "sans-serif"'
// tooltip 相关 dom 的 css 类名
const TOOLTIP_CONTAINER_CLASS = 'g2-tooltip'
const TOOLTIP_TITLE_CLASS = 'g2-tooltip-title'
const TOOLTIP_LIST_CLASS = 'g2-tooltip-list'
const TOOLTIP_LIST_ITEM_CLASS = 'g2-tooltip-list-item'
const TOOLTIP_MARKER_CLASS = 'g2-tooltip-marker'
const TOOLTIP_VALUE_CLASS = 'g2-tooltip-value'

// html 渲染的 legend 相关 dom 的 css 类型
const LEGEND_CONTAINER_CLASS = 'g2-legend'
const LEGEND_TITLE_CLASS = 'g2-legend-title'
const LEGEND_LIST_CLASS = 'g2-legend-list'
const LEGEND_LIST_ITEM_CLASS = 'g2-legend-list-item'
const LEGEND_MARKER_CLASS = 'g2-legend-marker'

const DefaultTheme = {
  background: {
    fill: ''
  },
  defaultColor: DEFAULT_COLOR, // 默认主题色
  plotCfg: {
    padding: [20, 20, 95, 80]
  },
  fontFamily: FONT_FAMILY,
  defaultLegendPosition: 'bottom', // 默认图例的展示位置
  colors: COLOR_PLATE_8,
  colors_16: COLOR_PLATE_16,
  colors_24: COLOR_PLATE_24,
  colors_pie: COLOR_PIE,
  colors_pie_16: COLOR_PIE_16,
  shapes: {
    point: [
      'hollowCircle',
      'hollowSquare',
      'hollowDiamond',
      'hollowBowtie',
      'hollowTriangle',
      'hollowHexagon',
      'cross',
      'tick',
      'plus',
      'hyphen',
      'line'
    ],
    line: ['line', 'dash', 'dot'],
    area: ['area']
  },
  sizes: [1, 10],
  opacities: [0.1, 0.9],
  axis: {
    top: {
      // zIndex: 1, // 默认上下方向的坐标轴位于左右坐标轴的上方
      position: 'top',
      title: null,
      label: {
        offset: 14,
        textStyle: {
          fill: '#545454',
          fontSize: 12,
          lineHeight: 20,
          textBaseline: 'middle',
          fontFamily: FONT_FAMILY
        },
        autoRotate: true
      },
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        stroke: '#BFBFBF',
        length: 4,
        alignWithLabel: true
      },
      grid: null
      // grid: {
      //   zIndex: -1,
      //   lineStyle: {
      //     stroke: '#E9E9E9',
      //     lineWidth: 1
      //   },
      //   hideFirstLine: true
      // }
    },
    bottom: {
      position: 'bottom',
      title: null,
      label: {
        offset: 22,
        autoRotate: true,
        textStyle: {
          fill: '#545454',
          fontSize: 12,
          lineHeight: 20,
          textBaseline: 'middle',
          fontFamily: FONT_FAMILY
        }
      },
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        stroke: '#BFBFBF',
        length: 4,
        alignWithLabel: true
      },
      grid: null
    },
    left: {
      position: 'left',
      title: null,
      label: {
        offset: 12,
        autoRotate: true,
        textStyle: {
          fill: '#545454',
          fontSize: 12,
          lineHeight: 20,
          textBaseline: 'middle',
          fontFamily: FONT_FAMILY
        }
      },
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        stroke: '#BFBFBF',
        length: 4,
        alignWithLabel: true
      },
      grid: null
    },
    right: {
      position: 'right',
      title: null,
      label: {
        offset: 12,
        autoRotate: true,
        textStyle: {
          fill: '#545454',
          fontSize: 12,
          lineHeight: 20,
          textBaseline: 'middle',
          fontFamily: FONT_FAMILY
        }
      },
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        stroke: '#BFBFBF',
        length: 4,
        alignWithLabel: true
      },
      grid: null
    },
    circle: {
      zIndex: 1,
      title: null,
      label: {
        offset: 12,
        textStyle: {
          fill: '#545454',
          fontSize: 12,
          lineHeight: 20,
          fontFamily: FONT_FAMILY
        }
      },
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        stroke: '#BFBFBF',
        length: 4,
        alignWithLabel: true
      },
      grid: null
      // grid: {
      //   lineStyle: {
      //     stroke: '#E9E9E9',
      //     lineWidth: 1,
      //     lineDash: [3, 3]
      //   },
      //   hideFirstLine: true
      // }
    },
    radius: {
      zIndex: 0,
      label: {
        offset: 12,
        textStyle: {
          fill: '#545454',
          fontSize: 12,
          textBaseline: 'middle',
          lineHeight: 20,
          fontFamily: FONT_FAMILY
        }
      },
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        stroke: '#BFBFBF',
        length: 4,
        alignWithLabel: true
      },
      grid: null
      // grid: {
      //   lineStyle: {
      //     stroke: '#E9E9E9',
      //     lineWidth: 1,
      //     lineDash: [3, 3]
      //   },
      //   type: 'circle'
      // }
    },
    helix: {
      label: {
        textStyle: {
          fill: '#545454',
          fontSize: 12
        }
      },
      title: null,
      line: {
        lineWidth: 1,
        stroke: '#BFBFBF'
      },
      tickLine: {
        lineWidth: 1,
        length: 4,
        stroke: '#BFBFBF',
        alignWithLabel: true
      },
      grid: null
      // grid: {
      //   lineStyle: {
      //     stroke: '#E9E9E9',
      //     lineWidth: 1
      //   },
      //   type: 'circle'
      // }
    }
  },
  label: {
    offset: 20,
    textStyle: {
      fill: '#545454',
      fontSize: 12,
      textBaseline: 'middle',
      fontFamily: FONT_FAMILY
    }
  },
  treemapLabels: {
    offset: 10,
    textStyle: {
      fill: '#fff',
      fontSize: 12,
      textBaseline: 'top',
      fontStyle: 'bold',
      fontFamily: FONT_FAMILY
    }
  },
  innerLabels: {
    textStyle: {
      fill: '#fff',
      fontSize: 12,
      textBaseline: 'middle',
      fontFamily: FONT_FAMILY
    }
  },
  // 在theta坐标系下的饼图文本内部的样式
  thetaLabels: {
    labelLine: {
      lineWidth: 1
    },
    labelHeight: 14,
    offset: 30
    // 在theta坐标系下的饼图文本的样式
  },
  legend: {
    right: {
      position: 'right',
      layout: 'vertical',
      itemMarginBottom: 8, // layout 为 vertical 时各个图例项的间距
      width: 16,
      height: 156,
      title: null,
      textStyle: {
        fill: '#8C8C8C',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
        lineHeight: 20,
        fontFamily: FONT_FAMILY
      }, // 图例项文本的样式
      unCheckColor: '#bfbfbf'
    },
    left: {
      position: 'left',
      layout: 'vertical',
      itemMarginBottom: 8,
      width: 16,
      height: 156,
      title: null,
      textStyle: {
        fill: '#8C8C8C',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
        lineHeight: 20,
        fontFamily: FONT_FAMILY
      }, // 图例项文本的样式
      unCheckColor: '#bfbfbf'
    },
    top: {
      position: 'top',
      offset: 6,
      layout: 'horizontal',
      title: null,
      itemGap: 10,
      width: 156,
      height: 16,
      textStyle: {
        fill: '#8C8C8C',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
        lineHeight: 20,
        fontFamily: FONT_FAMILY
      }, // 图例项文本的样式
      unCheckColor: '#bfbfbf'
    },
    bottom: {
      position: 'bottom',
      offset: 6,
      layout: 'horizontal',
      title: null,
      itemGap: 10,
      width: 156,
      height: 16,
      textStyle: {
        fill: '#8C8C8C',
        fontSize: 12,
        textAlign: 'start',
        textBaseline: 'middle',
        lineHeight: 20,
        fontFamily: FONT_FAMILY
      }, // 图例项文本的样式
      unCheckColor: '#bfbfbf'
    },
    // 定义 html 渲染图例的样式
    html: {
      [`${LEGEND_CONTAINER_CLASS}`]: {
        height: 'auto',
        width: 'auto',
        position: 'absolute',
        overflow: 'auto',
        fontSize: '12px',
        fontFamily: FONT_FAMILY,
        lineHeight: '20px',
        color: '#8C8C8C'
      },
      [`${LEGEND_TITLE_CLASS}`]: {
        marginBottom: '4px'
      },
      [`${LEGEND_LIST_CLASS}`]: {
        listStyleType: 'none',
        margin: 0,
        padding: 0
      },
      [`${LEGEND_LIST_ITEM_CLASS}`]: {
        cursor: 'pointer',
        marginBottom: '5px',
        marginRight: '24px'
      },
      [`${LEGEND_MARKER_CLASS}`]: {
        width: '9px',
        height: '9px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '8px',
        verticalAlign: 'middle'
      }
    },
    // 不能滑动的连续图例样式
    gradient: {
      textStyle: {
        fill: '#8C8C8C',
        fontSize: 12,
        textAlign: 'center',
        textBaseline: 'middle',
        lineHeight: 20,
        fontFamily: FONT_FAMILY
      }, // 图例项文本的样式
      lineStyle: {
        lineWidth: 1,
        stroke: '#fff'
      },
      unCheckColor: '#bfbfbf'
    },
    margin: [0, 20, 24, 5], // 图例跟四个边的坐标轴、绘图区域的间距
    legendMargin: 24 // 图例之间的间距
  },
  tooltip: {
    crosshairs: false,
    offset: 15,
    // css style for tooltip
    [`${TOOLTIP_CONTAINER_CLASS}`]: {
      position: 'absolute',
      visibility: 'hidden',
      // @2018-07-25 by blue.lb 这里去掉浮动，火狐上存在样式错位
      // whiteSpace: 'nowrap',
      zIndex: 8,
      transition:
        'visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1), left 0.4s cubic-bezier(0.23, 1, 0.32, 1), top 0.4s cubic-bezier(0.23, 1, 0.32, 1)',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      boxShadow: '0px 0px 10px #aeaeae',
      borderRadius: '3px',
      color: 'rgb(87, 87, 87)',
      fontSize: '12px',
      fontFamily: FONT_FAMILY,
      lineHeight: '20px',
      padding: '10px 10px 6px 10px'
    },
    [`${TOOLTIP_TITLE_CLASS}`]: {
      marginBottom: '4px'
    },
    [`${TOOLTIP_LIST_CLASS}`]: {
      margin: 0,
      listStyleType: 'none',
      padding: 0
    },
    [`${TOOLTIP_LIST_ITEM_CLASS}`]: {
      marginBottom: '4px'
    },
    [`${TOOLTIP_MARKER_CLASS}`]: {
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      display: 'inline-block',
      marginRight: '8px'
    },

    [`${TOOLTIP_VALUE_CLASS}`]: {
      display: 'inline-block',
      float: 'right',
      marginLeft: '30px'
    }
  },
  tooltipMarker: {
    symbol: (x, y, r) => {
      return [
        ['M', x, y],
        ['m', -r, 0],
        ['a', r, r, 0, 1, 0, r * 2, 0],
        ['a', r, r, 0, 1, 0, -r * 2, 0]
      ]
    },
    stroke: '#fff',
    shadowBlur: 8,
    shadowOffsetX: 0,
    shadowOffSetY: 0,
    radius: 4
  }, // 提示信息在折线图、区域图上形成点的样式
  tooltipCrosshairsRect: {
    type: 'rect',
    style: {
      fill: '#CCD6EC',
      opacity: 0.3
    }
  }, // tooltip 辅助背景框样式
  tooltipCrosshairsLine: {
    style: {
      stroke: 'rgba(0, 0, 0, 0.25)',
      lineWidth: 1
    }
  },
  shape: {
    point: {
      lineWidth: 1,
      fill: DEFAULT_COLOR,
      radius: 4
    },
    hollowPoint: {
      fill: '#fff',
      lineWidth: 1,
      stroke: DEFAULT_COLOR,
      radius: 3
    },
    interval: {
      lineWidth: 0,
      fill: DEFAULT_COLOR,
      fillOpacity: 0.85
    },
    hollowInterval: {
      fill: '#fff',
      stroke: DEFAULT_COLOR,
      fillOpacity: 0,
      lineWidth: 2
    },
    area: {
      lineWidth: 0,
      fill: DEFAULT_COLOR,
      fillOpacity: 0.3
    },
    polygon: {
      lineWidth: 0,
      fill: DEFAULT_COLOR,
      fillOpacity: 1
    },
    hollowPolygon: {
      fill: '#fff',
      stroke: DEFAULT_COLOR,
      fillOpacity: 0,
      lineWidth: 2
    },
    hollowArea: {
      fill: '#fff',
      stroke: DEFAULT_COLOR,
      fillOpacity: 0,
      lineWidth: 2
    },
    line: {
      stroke: DEFAULT_COLOR,
      lineWidth: 2,
      fill: null
    },
    edge: {
      stroke: DEFAULT_COLOR,
      lineWidth: 1,
      fill: null
    },
    schema: {
      stroke: DEFAULT_COLOR,
      lineWidth: 1,
      fill: null
    }
  },
  guide: {
    line: {
      lineStyle: {
        stroke: DEFAULT_COLOR,
        lineDash: [0, 2, 2],
        lineWidth: 1
      },
      text: {
        position: 'end',
        autoRotate: true,
        style: {
          fill: '#545454',
          fontSize: 12,
          textAlign: 'center',
          fontFamily: FONT_FAMILY
        }
      }
    },
    text: {
      style: {
        fill: '#545454',
        fontSize: 12,
        textBaseline: 'middle',
        textAlign: 'start',
        fontFamily: FONT_FAMILY
      }
    },
    region: {
      style: {
        lineWidth: 0, // 辅助框的边框宽度
        fill: '#000', // 辅助框填充的颜色
        fillOpacity: 0.04 // 辅助框的背景透明度
      } // 辅助框的图形样式属性
    },
    html: {
      alignX: 'middle',
      alignY: 'middle'
    }
  },
  pixelRatio: null
}

function GenerateTheme (param) {
  const newTheme = _.cloneDeep(DefaultTheme)
  const { colors, textColor, fontSize } = param
  if (_.isArray(colors) && colors.length) {
    const defaultColor = colors[0]
    newTheme['defaultColor'] = defaultColor
    newTheme['colors'] = repeatArray(colors, 8)
    newTheme['colors_16'] = repeatArray(colors, 16)
    newTheme['colors_24'] = repeatArray(colors, 24)
    newTheme['colors_pie'] = repeatArray(colors, 8)
    newTheme['colors_pie_16'] = repeatArray(colors, 16)
    newTheme['shape']['point']['fill'] = defaultColor
    newTheme['shape']['hollowPoint']['fill'] = defaultColor
    newTheme['shape']['interval']['fill'] = defaultColor
    newTheme['shape']['area']['fill'] = defaultColor
    newTheme['shape']['polygon']['fill'] = defaultColor
    newTheme['shape']['hollowPolygon']['fill'] = defaultColor
    newTheme['shape']['hollowArea']['fill'] = defaultColor
    newTheme['shape']['line']['stroke'] = defaultColor
    newTheme['shape']['edge']['stroke'] = defaultColor
    newTheme['shape']['schema']['stroke'] = defaultColor
  }
  if (textColor) {
    const lineColor = `${textColor}AA`
    // const gridColor = `${textColor}33`
    // axis
    // top
    newTheme['axis']['top']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['top']['line']['stroke'] = lineColor
    newTheme['axis']['top']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['top']['grid']['lineStyle']['stroke'] = gridColor
    // bottom
    newTheme['axis']['bottom']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['bottom']['line']['stroke'] = lineColor
    newTheme['axis']['bottom']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['bottom']['grid']['lineStyle']['stroke'] = gridColor
    // right
    newTheme['axis']['right']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['right']['line']['stroke'] = lineColor
    newTheme['axis']['right']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['right']['grid']['lineStyle']['stroke'] = gridColor
    // left
    newTheme['axis']['left']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['left']['line']['stroke'] = lineColor
    newTheme['axis']['left']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['left']['grid']['lineStyle']['stroke'] = gridColor
    // circle
    newTheme['axis']['circle']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['circle']['line']['stroke'] = lineColor
    newTheme['axis']['circle']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['circle']['grid']['lineStyle']['stroke'] = gridColor
    // radius
    newTheme['axis']['radius']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['radius']['line']['stroke'] = lineColor
    newTheme['axis']['radius']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['radius']['grid']['lineStyle']['stroke'] = gridColor
    // helix
    newTheme['axis']['helix']['label']['textStyle']['fill'] = textColor
    newTheme['axis']['helix']['line']['stroke'] = lineColor
    newTheme['axis']['helix']['tickLine']['stroke'] = lineColor
    // newTheme['axis']['helix']['grid']['lineStyle']['stroke'] = gridColor

    // label
    newTheme['label']['textStyle']['fill'] = textColor
    newTheme['treemapLabels']['textStyle']['fill'] = textColor
    newTheme['innerLabels']['textStyle']['fill'] = textColor

    // legend
    newTheme['legend']['top']['textStyle']['fill'] = textColor
    newTheme['legend']['top']['unCheckColor'] = lineColor
    newTheme['legend']['bottom']['textStyle']['fill'] = textColor
    newTheme['legend']['bottom']['unCheckColor'] = lineColor
    newTheme['legend']['right']['textStyle']['fill'] = textColor
    newTheme['legend']['right']['unCheckColor'] = lineColor
    newTheme['legend']['left']['textStyle']['fill'] = textColor
    newTheme['legend']['left']['unCheckColor'] = lineColor
    // gradient
    newTheme['legend']['gradient']['textStyle']['fill'] = textColor
    newTheme['legend']['gradient']['unCheckColor'] = lineColor
  }
  if (fontSize) {
    // axis
    newTheme['axis']['top']['label']['textStyle']['fontSize'] = fontSize
    newTheme['axis']['bottom']['label']['textStyle']['fontSize'] = fontSize
    newTheme['axis']['right']['label']['textStyle']['fontSize'] = fontSize
    newTheme['axis']['left']['label']['textStyle']['fontSize'] = fontSize
    newTheme['axis']['circle']['label']['textStyle']['fontSize'] = fontSize
    newTheme['axis']['radius']['label']['textStyle']['fontSize'] = fontSize
    newTheme['axis']['helix']['label']['textStyle']['fontSize'] = fontSize
    // label
    newTheme['label']['textStyle']['fontSize'] = fontSize
    newTheme['treemapLabels']['textStyle']['fontSize'] = fontSize
    newTheme['innerLabels']['textStyle']['fontSize'] = fontSize
    // legend
    newTheme['legend']['top']['textStyle']['fontSize'] = fontSize
    newTheme['legend']['bottom']['textStyle']['fontSize'] = fontSize
    newTheme['legend']['right']['textStyle']['fontSize'] = fontSize
    newTheme['legend']['left']['textStyle']['fontSize'] = fontSize
    // gradient
    newTheme['legend']['gradient']['textStyle']['fontSize'] = fontSize
  }
  return newTheme
}

export { DefaultTheme, GenerateTheme }
