<template>
  <v-chart
    v-if="ready"
    class="facet-chart"
    :forceFit="true"
    :height="canvasHeight"
    :data="dataSource"
    :scale="scale"
    :key="key"
    :padding="padding"
    :theme="theme">
    <table border="1" class="division-table">
      <tr v-for="(facetItem, index) in facetArray" :key="index">
        <td ref="seriesTitle" :style="{color: labelColor, fontSize: labelFontSize + 'px'}">{{ index === facetLen ? '': facetItem }}</td>
        <td ref="chartTd">
          <v-chart
            v-if="ready"
            :forceFit="true"
            :key="key + facetPaddingFinal[2]"
            :height="index === facetLen? trBottomHeight : trHeight"
            :data="facetArrGroup[index]"
            :scale="scale"
            :padding="index === facetLen ? facetPaddingFinal: facetPadding"
            :onClick="onClick"
            :theme="theme">
            <v-tooltip />
            <v-coord type="rect" direction="BL" />
            <v-axis :dataKey="xAxisName" :tickLine="tickLine" :label="label" />
            <template v-if="metricsLen === 1">
              <v-axis :dataKey="yAxisName1" :label="yAxisLabel"/>
              <v-line v-if="chartType === 'Line'" :position="position" :color="colors[index % 8]" />
              <v-point v-if="chartType === 'Line'" :position="position" :color="colors[index % 8]" shape="circle" />
              <v-bar v-if="chartType === 'Bar' || chartType === 'Column'" :position="position" :color="colors[index % 8]" />
              <v-stack-area v-if="chartType === 'Area'" :position="position" :color="colors[index % 8]" />
            </template>
            <template v-else>
              <v-axis :dataKey="yAxisName1" :tickLine="tickLine" :label="yAxisLabel"/>
              <v-axis :dataKey="yAxisName2" :label="yAxisLabel" />

              <template v-if="chartType === 'Line'">
                <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
              </template>
              <template v-if="chartType === 'Column'">
                <v-stack-bar v-if="seriesName1 && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
                <v-bar v-else-if="seriesName1 && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
                <v-bar v-else :position="xAxisName + '*' + yAxisName1" />
              </template>
              <template v-if="chartType === 'Area'">
                <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" adjust="stack" />
                <v-stack-area :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
              </template>
              <template v-if="chartType === 'LineColumn'">
                <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
                <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" />
                <!-- <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" /> -->
                <!-- <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" /> -->
              </template>
            </template>
          </v-chart>
        </td>
      </tr>
    </table>
  </v-chart>
</template>
<script>
// https://viserjs.gitee.io/demo.html
import _, { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
const DataSet = require('@antv/data-set')

export default {
  name: 'FacetChart',
  components: {},
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    chartType: {
      type: String,
      default: 'Line'
    },
    seriesType: {
      type: String,
      default: 'Group'
    },
    xAxis: {
      type: String,
      default: ''
    },
    smartChartHeight: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      canvasHeight: this.smartChartHeight,
      dataSource: [],
      xAxisName: '', // x轴键名
      xAxisLen: 0, // x轴唯一数量
      maxXtagLength: 0,
      yAxisName1: '', // y轴键名
      yAxisName2: '', // y轴键名
      facetField: '', // 除x轴的另一维度，显示在表格第一列
      facetLen: 0, // 系列唯一数量
      seriesName1: '', // 分颜色的字段
      seriesName2: '', // 分颜色的字段
      facetPadding: [15, 45, 10, 45],
      facetPaddingFinal: [-60, 45, 43, 45],
      padding: [0],
      tickLine: {
        alignTick: false
      },
      trHeight: 100,
      trBottomHeight: 30,
      facetArray: [],
      colors: ['#1890FF', '#2FC25B', '#FACC14', '#223273', '#8543E0', '#13C2C2', '#3436C7', '#F04864'],
      xAxisTags: [],
      facetArrGroup: [],
      metricsLen: 0,
      adjust: [{
        type: 'dodge',
        marginRatio: 0
      }],
      lastClickMess: {},
      sortDataSource1: [],
      sortDataSource2: [],
      key: uuidv4()
    }
  },
  computed: {
    labelFontSize () {
      if (this.theme.label) return this.theme.label.textStyle.fontSize
      else return 12
    },
    labelColor () {
      if (this.theme.label) return this.theme.label.textStyle.fill
      else return '#888888'
    },
    label () {
      return {
        autoRotate: false,
        rotate: this.maxXtagLength > 12 ? 70 : 45,
        textStyle: {
          fontSize: this.labelFontSize,
          textAlign: 'start'
        },
        offset: 15
        // offset: 45
      }
    },
    scale () {
      const ifStack = (this.chartType === 'Area' && this.metricsLen === 2) || (this.chartType === 'Column' && this.seriesType === 'stack')
      return [
        {
          dataKey: this.xAxisName,
          tickCount: this.xAxisLen > 16 ? 14 : this.xAxisLen > 6 ? 12 : this.xAxisLen,
          sync: false
        },
        {
          dataKey: this.yAxisName1,
          tickCount: 5,
          max: ifStack ? this.sortDataSource1[0][this.yAxisName1] + this.sortDataSource1[1][this.yAxisName1] : this.sortDataSource1[0][this.yAxisName1],
          min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) < 0 ? Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) : 0,
          sync: true
        },
        {
          dataKey: this.yAxisName2,
          max: this.yAxisName2 ? ifStack ? this.sortDataSource2[0][this.yAxisName2] + this.sortDataSource2[1][this.yAxisName2] : this.sortDataSource2[0][this.yAxisName2] : 0,
          min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) < 0 ? Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) : 0,
          sync: true
        }
      ]
    },
    position () {
      return `${this.xAxisName}*${this.yAxisName1}`
    },
    yAxisLabel () {
      return {
        formatter(val) {
          return simplifyNumber(val)
        },
        textStyle: {
          fontSize: this.labelFontSize,
          fill: this.labelColor
        }
      }
    }
  },
  mounted() {
    this.dataSource = this.reportData['dataItems']
    if (this.xAxis) {
      this.xAxisName = this.xAxis
      this.facetField = this.reportData['dimensions'].find((item) => item !== this.xAxisName)
    } else {
      const _xAxes = this.reportData['dimensions'].slice(0, 2)
      const _xAxesLens = [_.uniqBy(this.dataSource, _xAxes[0]).length, _.uniqBy(this.dataSource, _xAxes[1]).length]
      if (_xAxesLens[0] > _xAxesLens[1]) _xAxes.reverse()
      this.facetField = _xAxes[0]
      this.xAxisName = _xAxes[1]
    }
    const dv = new DataSet.View().source(this.dataSource)
    // console.log('dataSource', this.dataSource)
    dv.transform({
      type: 'fill-rows',
      groupBy: [ this.xAxisName ],
      orderBy: [ this.facetField ],
      fillBy: 'order' // 默认为 group，可选值：order
    })
    // console.log('0', dv0.rows)
    // this.dataSource = dv0.rows

    this.metricsLen = this.reportData['metrics'].length
    if (this.metricsLen > 1) {
      const _metrics1 = this.reportData.units[0]['metrics']
      // const dv = new DataSet.View().source(this.dataSource)
      dv.transform({
        type: 'fold',
        fields: _metrics1,
        key: 'metricsName1',
        value: 'metricsValue1'
      })
      this.seriesName1 = 'metricsName1'
      this.yAxisName1 = 'metricsValue1'

      if (this.reportData.units.length === 2) {
        const _metrics2 = this.reportData.units[1]['metrics']
        dv.transform({
          type: 'fold',
          fields: _metrics2,
          key: 'metricsName2',
          value: 'metricsValue2'
        })
        this.seriesName2 = 'metricsName2'
        this.yAxisName2 = 'metricsValue2'
      }
    } else {
      this.yAxisName1 = this.reportData['metrics'][0]
    }

    //  5.5 * this.axiasTitleLen
    this.dataSource = dv.rows.map(item => {
      if (this.yAxisName1 && (isNaN(item[this.yAxisName1]) || typeof item[this.yAxisName1] !== 'number')) item[this.yAxisName1] = 0
      if (this.yAxisName2 && (isNaN(item[this.yAxisName2]) || typeof item[this.yAxisName2] !== 'number')) item[this.yAxisName2] = 0
      return item
    })
    const _this = this
    if (this.yAxisName1) {
      this.sortDataSource1 = JSON.parse(JSON.stringify(this.dataSource))
      this.sortDataSource1.sort(function(a, b) {
        return b[_this.yAxisName1] - a[_this.yAxisName1]
      })
    }

    if (this.yAxisName2) {
      this.sortDataSource2 = JSON.parse(JSON.stringify(this.dataSource))
      this.sortDataSource2.sort(function(a, b) {
        return b[_this.yAxisName2] - a[_this.yAxisName2]
      })
    }

    this.xAxisLen = getDiffValue(this.dataSource, this.xAxisName).length
    this.facetArray = getDiffValue(this.reportData.dataItems, this.facetField)
    this.facetLen = this.facetArray.length
    this.facetArray.push(this.facetArray[0]) // 增加最后一行显示坐标轴
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)

    this.getFacetDataArr()
    this.getPaddingBottom()
    this.getPaddingRight()

    this.getChartHeight()
    this.ready = true
    this.chartRenderedDelay()
  },
  methods: {
    getFacetDataArr () {
      this.facetArrGroup.splice(0)
      this.facetArray.forEach((facetItem, index) => { // 得到子图数据数组 arrGroup
        this.facetArrGroup.push(this.dataSource.filter(item => item[this.facetField] === facetItem))
      })
      // console.log('dataSource', this.dataSource)
      // console.log('facetArrGroup', this.facetArrGroup)
      const sortXTagesArr = this.xAxisTags.sort()
      this.facetArrGroup = this.facetArrGroup.map(arr => {
        const newArr = []
        // console.log('arr', arr)
        // console.log('metricsValue1 = ', Math.max.apply(Math, arr.map(x => x[this.yAxisName1])))
        sortXTagesArr.forEach(xTag => {
          // console.log('xTag', xTag)
          newArr.push(...arr.filter(item => item[this.xAxisName] === xTag)) // 指标和X都相等的项存在
        })
        // console.log('newArr', newArr)
        return newArr
      })
    },
    getChartHeight () {
      this.$nextTick(() => { // 计算高度
        const facetTitleArr = this.$refs['seriesTitle']
        // console.log('facetTitleArr', facetTitleArr)
        const facetTitleHeightArr = facetTitleArr.map(item => item.offsetHeight)

        // console.log('facetTitleHeightArr', facetTitleHeightArr)
        const _height = facetTitleHeightArr.reduce(function(prev, cur, index, array) {
          return prev + cur
        }) + 10 // 表格开头有10px
        // console.log('canvasHeight', _height)
        if (_height > this.smartChartHeight) {
          this.canvasHeight = _height
        } else {
          this.canvasHeight = this.smartChartHeight
          this.trHeight = Math.max(this.trHeight, (this.canvasHeight - facetTitleHeightArr[facetTitleHeightArr.length - 1]) / this.facetLen - 7 * this.facetLen - 5) // 减去每行比图表多出的空间,判断是否因为title过长需要加长显示
        }
      })
    },
    getMaxXTagLength () {
      this.maxXtagLength = 0
      // let wordLength = 0
      this.xAxisTags.forEach((item) => {
        const len = getStrFullLength(item)
        if (len > this.maxXtagLength) {
          this.maxXtagLength = len
        }
      })
    },
    getPaddingBottom () {
      this.getMaxXTagLength()
      if (this.maxXtagLength > 16) {
        this.$nextTick(() => {
          let XTagMaxHeight = 0
          const xTagHtmlArr = document.getElementsByClassName('multi-label-html-text')
          // console.log('xTagHtmlArr', xTagHtmlArr)
          xTagHtmlArr.forEach(item => {
            // console.log('item.offsetHeight', item.offsetHeight)
            XTagMaxHeight = item.offsetHeight > XTagMaxHeight ? item.offsetHeight : XTagMaxHeight
            // console.log('XTagMaxHeight', XTagMaxHeight)
          })
          // console.log('XTagMaxHeight', XTagMaxHeight)
          this.facetPaddingFinal[2] = XTagMaxHeight + 5
          this.trBottomHeight = XTagMaxHeight
        })
      } else {
        const wordWidth = this.labelFontSize / 2
        // const wordWidth = this.maxXtagLength > 12 ? 5.5 : 8 // 每个字节5.5长度，maxLength为字节长度
        if (this.maxXtagLength > 12) { // 倾斜时会有重叠情况，45度时，重叠一半，70度时，按8字节重复1字节算, +10为尾部空隙
          this.trBottomHeight = 13 + (this.maxXtagLength - Math.floor(this.maxXtagLength / 8)) * wordWidth
        } else {
          this.trBottomHeight = 13 + (this.maxXtagLength / 2 + 2) * wordWidth
        }
        // this.trBottomHeight = this.maxXtagLength * wordWidth// 每个字11
        this.facetPaddingFinal[2] = 10 + this.trBottomHeight
        // console.log('getPaddingBottom', this.facetPaddingFinal[2])
        // this.padding[2] = this.maxXtagLength * wordWidth
      }
      // this.padding[2] = 11 + this.maxXtagLength * 9 // 单个图时，每个字节9
    },
    getPaddingRight () {
      this.getMaxXTagLength()
      const len = this.xAxisTags.length
      let i = len - 2
      let tag = 1
      let maxLength = getStrFullLength(this.xAxisTags[len - 1])
      const wordWidth = (this.maxXtagLength > 12 ? 2.5 : 6) / 12 * this.labelFontSize // 每个字节2.5宽度，maxLength为字节长度
      const wordNum = (this.maxXtagLength > 12 ? 5 : 2) / 12 * this.labelFontSize
      while (i >= 0) {
        // 5表示当前一个比后一个多5个字以上时，才会导致前一个标签为到右边最远的标签
        // 将离右边最近的标签与其之前的标签对比，得到往右延伸更远的标签
        if (maxLength + wordNum * tag < getStrFullLength(this.xAxisTags[i])) {
          maxLength = getStrFullLength(this.xAxisTags[i])
          tag = 1
        }
        i--
        tag++
      }
      this.facetPaddingFinal[1] = Math.max(this.facetPaddingFinal[1], maxLength * wordWidth - 6) // 减去为0时已经显示的部分
      this.facetPadding[1] = Math.max(this.facetPadding[1], maxLength * wordWidth - 6)
    },
    onClick(ev, chart) {
      if (this.lastClickMess.ev && this.lastClickMess.ev.toElement !== ev.toElement) {
        this.lastClickMess.chart.hideTooltip()
      }

      this.lastClickMess = {
        ev: ev,
        chart: chart
      }
    }
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created() {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.facet-chart {
  position: relative;
  .division-table {
    width: 100%;
    position: absolute;
    top: 10px;
    border: none;
    // border-color: #c4c4c4;
    z-index: 2;
    border-spacing: 0;
    // border-left: none;
    // border-right: none;
    // td {
    //   canvasHeight: 238px;
    // }
    tr:first-child {
      td {
        border-top-style: solid !important;
        border-top-color: #eeeeee !important;
      }
    }
    tr:last-child {
      td {
        border: none;
      }
    }
    tr {
      height: 100%;
      td:first-child {
        text-align: center;
        border-left: none;
        border-top: none;
        border-width: 1px;
        width: 20%;
        font-size: 0.75em;
      }
      td:last-child {
        border-right: none;
        border-left: none;
        border-top: none;
        border-width: 1px;
        font-size: 0.75em;
      }
    }
  }
}
</style>
