<template>
  <v-chart :forceFit="true" :height="height" :data="dataSource">
    <v-tooltip />
    <v-axis :dataKey="axis1Opts.dataKey" :line="axis1Opts.line" :tickLine="axis1Opts.tickLine" :grid="axis1Opts.grid" />
    <v-axis :dataKey="axis2Opts.dataKey" :line="axis2Opts.line" :tickLine="axis2Opts.tickLine" :grid="axis2Opts.grid" />
    <v-legend dataKey="categoryName" marker="circle" :offset="30" />
    <v-coord type="polar" :radius="0.8" />
    <v-line :position="position" color="categoryName" :size="2" />
    <v-point :position="position" color="categoryName" :size="4" shape="circle" />
    <v-area :position="position" color="categoryName" />
  </v-chart>
</template>
<script>
// https://viserjs.gitee.io/demo.html
const DataSet = require('@antv/data-set')

// const sourceData = [
//   { item: 'Design', a: 70, b: 30 },
//   { item: 'Development', a: 60, b: 70 },
//   { item: 'Marketing', a: 50, b: 60 },
//   { item: 'Users', a: 40, b: 50 },
//   { item: 'Test', a: 60, b: 70 },
//   { item: 'Language', a: 70, b: 50 },
//   { item: 'Technology', a: 50, b: 40 },
//   { item: 'Support', a: 30, b: 40 },
//   { item: 'Sales', a: 60, b: 40 },
//   { item: 'UX', a: 50, b: 60 },
// ];

export default {
  name: 'RadarChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      dataSource: []
    }
  },
  computed: {
    scale() {
      return [{
        dataKey: 'categoryValue',
        min: 0,
        max: 80
      }]
    },
    position() {
      return this.reportData.dimensions[0] + '*categoryValue'
    },
    axis1Opts() {
      return {
        dataKey: this.reportData.dimensions[0],
        line: null,
        tickLine: null,
        grid: {
          lineStyle: {
            lineDash: null
          },
          hideFirstLine: false
        }
      }
    },
    axis2Opts() {
      return {
        dataKey: 'categoryValue',
        line: null,
        tickLine: null,
        grid: {
          type: 'polygon',
          lineStyle: {
            lineDash: null
          }
        }
      }
    }
  },
  mounted() {
    const dv = new DataSet.View().source(this.reportData.dataItems)
    dv.transform({
      type: 'fold',
      fields: this.reportData.metrics,
      key: 'categoryName',
      value: 'categoryValue'
    })
    this.dataSource = dv.rows
    console.log(this.dataSource)
    // const dv = new DataSet.View().source(sourceData);
    // dv.transform({
    //   type: 'fold',
    //   fields: ['a', 'b'],
    //   key: 'user',
    //   value: 'score',
    // });
    // const data = dv.rows;
  }
}
</script>

<style lang="less">
</style>
