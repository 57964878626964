<template>
  <v-chart
    v-if="ready"
    :forceFit="true"
    :height="canvasHeight"
    :data="dataSource"
    :padding="padding"
    :key="key + padding[2] + padding[3]"
    class="bar-chart"
    :theme="theme">
    <v-tooltip />
    <!-- <v-legend /> -->
    <v-coord type="rect" direction="LT" />
    <v-axis :dataKey="yAxisName" :label="yAxisLabel" position="right" :grid="null"/>
    <v-axis useHtml :dataKey="xAxisName" position="left" :label="maxXtagLength > 16 ? labelHtml : xAxisLabel"/>
    <v-stack-bar v-if="seriesName && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName" :color="seriesName" />
    <v-bar v-else-if="seriesName && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName" :color="seriesName" :adjust="adjust" />
    <v-bar v-else :position="xAxisName + '*' + yAxisName" :size="14"/>
  </v-chart>
</template>
<script>
import { simplifyNumber } from '@/utils/numberUtil'
import _, { debounce } from 'lodash'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
const DataSet = require('@antv/data-set')
/**
 * 只支持：
 * 1个指标，或者1~2个维度
 * 1+个指标相同单位，1~2个维度
 */
export default {
  name: 'BarChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    seriesType: {
      type: String,
      default: 'Stack'
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      canvasHeight: 300,
      dataSource: [],
      xAxisName: '',
      yAxisName: '',
      seriesName: '',
      xAxisLen: 0,
      seriesLen: 0,
      facetCol: 2,
      facetField: '',
      facetLen: 0,
      xAxisTags: [],
      yAxisTags: [],
      adjust: [{
        type: 'dodge',
        marginRatio: 1 / 32
      }],
      padding: [0, 40, 30, 0], // 12一个字，16为一个字都不显示
      key: uuidv4()
    }
  },
  computed: {
    labelFontSize () {
      if (this.theme.label) return this.theme.label.textStyle.fontSize
      else return 12
    },
    yAxisLabel() {
      return {
        formatter(val) {
          return simplifyNumber(val)
        },
        textStyle: {
          fontSize: '0.75em'
        }
      }
    },
    xAxisLabel () {
      return {
        textStyle: {
          fontSize: '0.75em'
        }
      }
    },
    labelHtml () {
      return {
        htmlTemplate: (text, item, index) => {
          return `<div class="bar-label-html-text" style="position: relative;left: -${this.padding[3] - 10}px;transform:translateY(-20%) scale(0.9)">${text}</div>`
        }
      }
    }
  },
  mounted() {
    this.xAxisName = this.xAxis || this.reportData.dimensions[0]
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    if (this.reportData.metrics.length > 1) {
      // 如果指标数量大于1，用指标分类成系列（color）
      const dv = new DataSet.View().source(this.reportData.dataItems)
      dv.transform({
        type: 'fold',
        fields: this.reportData.metrics,
        key: 'metricsName',
        value: 'metricsValue'
      })
      this.seriesName = 'metricsName'
      this.yAxisName = 'metricsValue'
      this.dataSource = dv.rows
      if (this.reportData.dimensions.length === 2) {
        this.facetField = this.reportData.dimensions.find(item => item !== this.xAxisName)
      }
    } else {
      this.yAxisName = this.reportData.metrics[0]
      this.dataSource = this.reportData.dataItems
      if (this.reportData.dimensions.length === 2) {
        this.seriesName = this.reportData.dimensions.find(item => item !== this.xAxisName)
      }
    }
    this.xAxisLen = _.uniqBy(this.dataSource, this.xAxisName).length
    this.seriesLen = _.uniqBy(this.dataSource, this.seriesName).length
    if (this.seriesType === 'Group') {
      const _height = this.xAxisLen * this.seriesLen * 40
      this.canvasHeight = _height > this.height ? _height : this.height
    } else {
      const _height = this.xAxisLen > 10 ? this.xAxisLen * 30 : this.xAxisLen * 35
      this.canvasHeight = _height > this.height ? _height : this.height
    }

    this.yAxisTags = getDiffValue(this.dataSource, this.yAxisName)
    this.ready = true
    this.chartRenderedDelay()
    this.getPaddingLeft()
    this.getPaddingRight()
  },
  methods: {
    getMaxXTagLength () {
      this.maxXtagLength = 0
      // let wordLength = 0
      this.xAxisTags.forEach((item) => {
        const len = getStrFullLength(item)
        if (len > this.maxXtagLength) {
          this.maxXtagLength = len
        }
      })
    },
    getPaddingLeft () {
      this.getMaxXTagLength()
      if (this.maxXtagLength > 16) {
        this.$nextTick(() => {
          let XTagMaxWidth = 0
          const xTagHtmlArr = document.getElementsByClassName('bar-label-html-text')
          xTagHtmlArr.forEach(item => {
            console.log(item.offsetWidth)
            XTagMaxWidth = item.offsetWidth > XTagMaxWidth ? item.offsetWidth : XTagMaxWidth
          })
          this.padding[3] = XTagMaxWidth
          this.$forceUpdate()
        })
        // this.padding[3] = 63
      } else {
        let maxLength = 0
        this.xAxisTags.forEach((item) => { // 得到最长的一个标签的长度
          if (getStrFullLength(item) > maxLength) {
            maxLength = getStrFullLength(item)
          }
        })
        if (this.theme['label']) {
          const wordWidth = this.labelFontSize
          this.padding[3] = maxLength / 2 * wordWidth + 15 // 每个字wordWidth，15为尾部空间
        } else {
          this.padding[3] = maxLength / 2 * 12 + 15// 每个字12，多15为尾部空间
        }
      }
    },
    getPaddingRight () {
      const len = this.yAxisTags.length
      let i = len - 2
      let maxLength = getStrFullLength(simplifyNumber(this.yAxisTags[len - 1]).toString())
      while (i >= 0) {
        const strLen = getStrFullLength(simplifyNumber(this.yAxisTags[i]).toString())
        if (maxLength < strLen) {
          maxLength = strLen
        }
        i--
      }
      this.padding[1] = maxLength * 6
    }
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.bar-chart {
  position: relative;
  .g-labels {
    width: 100%;
    .g-label {
      // width: 100%;
      width: calc(100% / 6);
      height: 20px;
      .bar-label-html-text {
        font-size: 0.75em;
        transform: scale(0.9);
        color: #808080;
        text-align: center;
      }
    }
  }
}
</style>
