<template>
  <div class="simple-chart-div">
    <v-chart
      class="simple-chart"
      v-if="ready"
      :forceFit="true"
      :scale="scale"
      :key="key + padding[2] + chartType"
      :height="fixHeight || canvasHeight"
      :data="dataSource"
      :padding="padding"
      :theme="theme">
      <v-tooltip />
      <v-axis :dataKey="yAxisName1" :label="yAxisLabel" />
      <v-axis useHtml :dataKey="xAxisName" :label="maxXtagLength > 16 ? labelHtml : label"/>
      <template v-if="chartType === 'Line'">
        <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
      </template>
      <template v-if="chartType === 'Column'">
        <v-stack-bar v-if="seriesName1 && seriesType === 'Stack'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
        <v-bar v-else-if="seriesName1 && seriesType === 'Group'" :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
        <v-bar v-else :position="xAxisName + '*' + yAxisName1" />
      </template>
      <template v-if="chartType === 'Area'">
        <v-line :position="xAxisName + '*' + yAxisName1" :color="seriesName1" adjust="stack" />
        <v-stack-area :position="xAxisName + '*' + yAxisName1" :color="seriesName1" />
      </template>
      <template v-if="chartType === 'LineColumn'">
        <v-axis :dataKey="yAxisName2" :label="yAxisLabel"/>
        <v-bar :position="xAxisName + '*' + yAxisName1" :color="seriesName1" :adjust="adjust" />
        <v-line :position="xAxisName + '*' + yAxisName2" :color="seriesName2" />
      </template>
      <v-guide v-if="reportData['predictStart']" type="region" :start="[reportData['predictStart'], 'min']" :end="[reportData['predictEnd'], 'max']" />
    </v-chart>
  </div>
</template>
<script>
// https://viserjs.gitee.io/demo.html
import { simplifyNumber } from '@/utils/numberUtil'
import { getStrFullLength, getDiffValue } from '@/utils/utils'
import _, { debounce } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
const DataSet = require('@antv/data-set')
/**
 * 只支持：
 * 1个指标，或者1~2个维度
 * 1+个指标相同单位，1~2个维度
 */
export default {
  name: 'SimpleRectChart',
  components: {},
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    chartType: {
      type: String,
      default: 'Line'
    },
    seriesType: {
      type: String,
      default: 'Stack'
    },
    height: {
      type: Number,
      default: 300
    },
    fixHeight: { // 固定不变高度
      type: Number,
      default: null
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      ready: false,
      canvasHeight: 300,
      dataSource: [],
      xAxisName: '',
      yAxisName1: '',
      seriesName1: '',
      xAxisLen: 0,
      xAxisTags: [],
      morespace: 40,
      adjust: [
        {
          type: 'dodge',
          marginRatio: 1 / 32
        }
      ],
      padding: [20, 45, 300, 45],
      maxXtagLength: 0,
      yAxisName2: '',
      seriesName2: '',
      XTagMaxHeight: 0,
      yhtmlTemple1: '',
      yhtmlOffsetX1: 0,
      yhtmlOffsetY: 0,
      yhtmlTemple2: '',
      key: uuidv4()
    }
  },
  computed: {
    labelFontSize () {
      if (this.theme.label) return this.theme.label.textStyle.fontSize
      else return 12
    },
    axisLabelColor () {
      if (this.theme.label) return this.theme.label.textStyle.fill
      else return '#545454'
    },
    scale () {
      const arr = [
        {
          dataKey: this.xAxisName,
          type: 'cat',
          tickCount: this.maxXtagLength > 16 ? (this.xAxisLen > 6 ? 6 : this.xAxisLen) : (this.xAxisLen > 12 ? 12 : this.xAxisLen),
          sync: false
        },
        {
          dataKey: this.yAxisName1,
          tickCount: 5,
          max: Math.max.apply(Math, this.dataSource.map(x => x[this.yAxisName1])),
          min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName1])) < 0 ? Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName1])) : 0,
          sync: true
        }
      ]
      if (this.yAxisName2) {
        arr.push({
          dataKey: this.yAxisName2,
          tickCount: 5,
          max: Math.max.apply(Math, this.dataSource.map(x => x[this.yAxisName2])),
          min: Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) < 0 ? Math.min.apply(Math, this.dataSource.map(x => x[this.yAxisName2])) : 0,
          sync: true
        })
      }
      return arr
    },
    yAxisLabel () {
      return {
        formatter (val) {
          return simplifyNumber(val)
        },
        textStyle: {
          fontSize: this.labelFontSize || '0.75em'
        }
      }
    },
    label () {
      return {
        autoRotate: false,
        // rotate: 70,
        rotate: this.maxXtagLength > 12 ? 70 : 45,
        offset: 15,
        textStyle: {
          fontSize: this.labelFontSize || '0.75em',
          textAlign: 'start'
        }
      }
    },
    labelHtml () {
      return {
        htmlTemplate: (text, item, index) => {
          return `<div class="simple-label-html-text" style="font-size:${this.axisLabelColor}">${text}</div>`
        }
      }
    }
  },
  mounted () {
    this.xAxisName = this.xAxis || this.reportData.dimensions[0]
    this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    if (this.reportData.metrics.length > 1) {
      const dv = new DataSet.View().source(this.reportData.dataItems)
      // 指标数量大于1，用指标分类成系列（color）
      dv.transform({
        type: 'fold',
        fields: this.reportData.units[0]['metrics'],
        key: 'metricsName1',
        value: 'metricsValue1'
      })
      this.seriesName1 = 'metricsName1'
      this.yAxisName1 = 'metricsValue1'
      if (this.reportData.units.length === 2) { // 如果单位不同（2个），以linecolumn形式显示
        dv.transform({
          type: 'fold',
          fields: this.reportData.units[1]['metrics'],
          key: 'metricsName2',
          value: 'metricsValue2'
        })
        this.seriesName2 = 'metricsName2'
        this.yAxisName2 = 'metricsValue2'
      }
      this.dataSource = dv.rows.map(item => {
        if (isNaN(item[this.yAxisName1]) || typeof item[this.yAxisName1] !== 'number') item[this.yAxisName1] = 0
        if (isNaN(item[this.yAxisName2]) || typeof item[this.yAxisName2] !== 'number') item[this.yAxisName2] = 0
        return item
      })
    } else {
      this.yAxisName1 = this.reportData.metrics[0]
      this.dataSource = this.reportData.dataItems
      if (this.reportData.dimensions.length === 2) {
        this.seriesName1 = this.reportData.dimensions.find((item) => item !== this.xAxisName)
      }
    }

    this.xAxisLen = _.uniqBy(this.dataSource, this.xAxisName).length
    this.canvasHeight = this.height
    this.getPaddingBottom()
    this.getPaddingRight()

    this.ready = true
    this.chartRenderedDelay()
  },
  methods: {
    getMaxXTagLength () {
      this.maxXtagLength = 0
      this.xAxisTags.forEach((item) => {
        const len = getStrFullLength(item)
        if (len > this.maxXtagLength) {
          this.maxXtagLength = len
        }
      })
    },
    getPaddingBottom () {
      this.getMaxXTagLength()
      this.XTagMaxHeight = 0
      if (this.maxXtagLength > 16) {
        this.$nextTick(() => {
          const xTagHtmlArr = document.getElementsByClassName('simple-label-html-text')
          xTagHtmlArr.forEach(item => {
            this.XTagMaxHeight = item.offsetHeight > this.XTagMaxHeight ? item.offsetHeight : this.XTagMaxHeight
          })
          this.padding[2] = this.XTagMaxHeight + 10
          if (this.padding[2] > this.canvasHeight) this.canvasHeight += this.padding[2]
          this.$forceUpdate()
        })
      } else {
        const wordWidth = this.labelFontSize / 2 // 每个字节6长度，maxXtagLength为字节长度
        if (this.maxXtagLength > 12) { // 倾斜时会有重叠情况，45度时，重叠一半，70度时，按8字节重复1字节算, +10为尾部空隙
          this.padding[2] = 15 + (this.maxXtagLength - Math.floor(this.maxXtagLength / 8)) * wordWidth + 10
        } else {
          this.padding[2] = 15 + (this.maxXtagLength / 2 + 2) * wordWidth + 10
        }
      }
    },
    getPaddingRight () {
      this.getMaxXTagLength()
      const len = this.xAxisTags.length
      let i = len - 2
      let tag = 1
      let maxLength = getStrFullLength(this.xAxisTags[len - 1])
      const wordWidth = (this.maxXtagLength > 12 ? 2.5 : 6) / 12 * this.labelFontSize // 每个字节2.5宽度，maxLength为字节长度
      const wordNum = (this.maxXtagLength > 12 ? 5 : 2) / 12 * this.labelFontSize
      while (i >= 0) {
        // 5表示当前一个比后一个多5个字以上时，才会导致前一个标签为到右边最远的标签
        // 将离右边最近的标签与其之前的标签对比，得到往右延伸更远的标签
        if (maxLength + wordNum * tag < getStrFullLength(this.xAxisTags[i])) {
          maxLength = getStrFullLength(this.xAxisTags[i])
          tag = 1
        }
        i--
        tag++
      }
      if (this.maxXtagLength < 16) { // 大于16时，用html模板
        this.padding[1] = Math.max(this.padding[1], maxLength * wordWidth - 6)
      }
    }
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
.simple-chart {
  position: relative;
  .g-labels {
    width: 100%;
    .g-label {
      // width: 100%;
      width: calc(100% / 6);
      height: 20px;
      .simple-label-html-text {
        font-size: 0.75em;
        transform: scale(0.9);
        color: #808080;
        text-align: center;
        word-break:break-all;
        padding: 0 5px;
      }
    }
  }
  .ytitle {
    font-size: 12px;
    transform: rotate(-90deg);
    color: #cdcdcd;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
