<template>
  <v-chart
    class="point-chart"
    v-if="ready"
    :forceFit="true"
    :height="height"
    :data="dataSource"
    :padding="padding"
    :key="key"
    :theme="theme">
    <v-tooltip :showTitle="false" :crosshairs="tooltipCrosshairs" />
    <v-legend />
    <v-axis :dataKey="xAxisName" :title="{ text: xAxisTitle, textStyle: {fill: yTitleColor} }" :label="xAxisLabel"/>
    <v-axis :dataKey="yAxisName" :title="{ text: yAxisTitle, textStyle: {fill: yTitleColor} }" :label="yAxisLabel"/>
    <v-point
      :color="seriesName"
      :position="xAxisName+ '*' + yAxisName"
      :size="4"
      :tooltip="pointTooltip"
      shape="circle" />
    <v-guide
      v-if="reportData['predictStart']"
      type="region"
      :start="[reportData['predictStart'], 'min']"
      :end="[reportData['predictEnd'], 'max']" />
  </v-chart>
  <!-- <div>
    <v-chart :forceFit="true" height="400" :data="data">
      <v-tooltip :showTitle="false" :crosshairs="tooltipCrosshairs" :itemTpl="tooltipItemTpl" />
      <v-axis />
      <v-point
        color="gender"
        position="height*weight"
        :size="4"
        :opacity="0.65"
        :tooltip="pointTooltip"
        shape="circle" />
    </v-chart>
  </div> -->
</template>

<script>
import { debounce } from 'lodash'
import { getDiffValue } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
import { simplifyNumber } from '@/utils/numberUtil'

export default {
  name: 'PointChart',
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      dataSource: [],
      padding: [40, 100, 100, 100],
      xAxisName: '',
      seriesName: '',
      yAxisName: '',
      dimensions: [],
      xAxisTitle: '',
      yAxisTitle: '',
      tooltipCrosshairs: { type: 'cross' },
      key: uuidv4()
    }
  },
  computed: {
    yTitleColor () {
      if (this.theme.label) return this.theme.label.textStyle.fill
      else return '#cccccc'
    },
    pointTooltip () {
      const dimensionsStr = this.dimensions.join('*')
      return [`${this.xAxisName}*${this.yAxisName}*${dimensionsStr}`]
    },
    yAxisLabel() {
      return {
        formatter(val) {
          return simplifyNumber(val)
        }
      }
    },
    xAxisLabel() {
      return {
        formatter(val) {
          return simplifyNumber(val)
        }
      }
    // },
    // color () {
    //   return [this.seriesName, ['#F04864', '#1890FF']]
    }
  },
  methods: {

  },
  mounted() {
    this.xAxisName = this.reportData['metrics'][0]
    this.yAxisName = this.reportData['metrics'][1]
    this.dimensions = this.reportData.dimensions
    this.dataSource = this.reportData.dataItems
    this.seriesName = this.reportData.dimensions[0]
    let shortestDataLen = getDiffValue(this.dataSource, this.seriesName).length
    this.dimensions.forEach(item => { // 维度数据最少得一个为颜色分类
      const data = getDiffValue(this.dataSource, item)
      if (data.length < shortestDataLen) {
        this.seriesName = item
        shortestDataLen = data.length
      }
    })
    this.xAxisTitle = this.xAxisName + `(${this.reportData.units[0]['unit'] ? this.reportData.units[0]['unit'] : ''})`
    this.yAxisTitle = this.yAxisName + `(${this.reportData.units[1] && this.reportData.units[1]['unit'] ? this.reportData.units[1]['unit'] : this.reportData.units[0]['unit']})`
    this.dataSource.sort((a, b) => a[this.seriesName].localeCompare(b[this.seriesName], 'zh')) // a~z 排序
    // const _unit = this.reportData.units[0]['unit']
    // const _unitEnclosed = _unit ? `（${_unit}）` : ''

    // this.xAxisTags = getDiffValue(this.reportData.dataItems, this.xAxisName)
    // if (this.reportData['dimensions'].length > 1) {
    //   // 如果维度数量大于1，用最短的维度分类成系列（color）

    //   this.seriesName = 'metricsName'
    //   this.yAxisName = 'metricsValue'
    //   this.yAxisTitle = this.reportData['metrics'].join('、') + _unitEnclosed
    // this.dataSource = dv.rows
    //   if (this.reportData['dimensions'].length === 2) {
    //     this.facetField = this.reportData['dimensions'].find(item => item !== this.xAxisName)
    //   }
    // } else {
    //   this.yAxisName = this.reportData['metrics'][0]
    //   this.yAxisTitle = this.reportData['metrics'][0] + _unitEnclosed
    //   this.dataSource = this.reportData['dataItems']
    //   if (this.reportData['dimensions'].length === 2) {
    //     this.seriesName = this.reportData['dimensions'].find(item => item !== this.xAxisName)
    //   }
    // }
    // this.xAxisLen = _.uniqBy(this.dataSource, this.xAxisName).length
    // this.seriesArray = _.uniqBy(this.dataSource, this.seriesName).map(item => item[this.seriesName])
    // this.seriesLen = _.uniqBy(this.dataSource, this.seriesName).length
    // if (this.facetField) {
    //   this.facetArray = _.uniqBy(this.dataSource, this.facetField).map(item => item[this.facetField])
    //   this.facetLen = _.uniqBy(this.dataSource, this.facetField).length
    //   // if (this.xAxisLen > 8) this.facetCol = 1
    //   const _height = Math.ceil(this.facetLen / this.facetCol) * 100 + this.trBottomHeight // x轴标签
    //   this.canvasHeight = _height > this.height ? _height : this.height - 70
    //   this.trHeight = (this.canvasHeight - 15 - this.trBottomHeight) / this.facetLen - 8 // 去掉表格每行多占用部分8px
    //   this.getFacetDataArr()
    // } else {
    //   this.canvasHeight = this.height
    // }

    // this.morespace = this.reportData.series.length < 20 ? 45 : (this.reportData.series.length < 40 ? 105 : (this.reportData.series.length < 60 ? 155 : 200))
    this.ready = true
    this.chartRenderedDelay()
    // this.getDiffValue(this.reportData.dataItems)
    // this.getPaddingBottom()
    // this.getPaddingRight()
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>
<style lang="less">
</style>
