// import _ from 'lodash'

export default class KeywordMatched {
  keyword = ''
  keywordArray = []
  originData = []
  keywordList = []
  adviseXAxis = ''
  adviseDatetimeAxis = ''

  constructor(keyword, keywordMatch) {
    this.keyword = keyword
    this.originData = JSON.parse(JSON.stringify(keywordMatch))
    this.keywordList = JSON.parse(JSON.stringify(keywordMatch))
    this.formatMatchData()
    // this.fillKeywordPosition()
    this.calcAdviseXAxis()
  }
  formatMatchData () {
    this.keywordList.forEach(keyword => {
      if (!keyword['matchedValue']) keyword['matchedValue'] = keyword['keywordInput']
      // if (keyword['totalMatchKey']) keyword['matchedValue'] = keyword['totalMatchKey']
      if (keyword['originMatchedValue']) keyword['matchedValue'] = keyword['originMatchedValue']
      keyword['keywordCategoryDetail'] = this.parseCategoryDetail(keyword)
      this.keywordArray.push(keyword['matchedValue'])
    })
  }

  parseCategoryDetail (keyword) {
    if (keyword['keywordCategory'] === 'DATETIME') {
      if (/^\d{4}年$/.test(keyword['matchedValue'])) {
        return 'year'
      } else if (/^\d{4}年\d{1,2}月$/.test(keyword['matchedValue'])) {
        return 'month'
      } else if (/^\d{4}年\d{1,2}月\d{1,2}(日|号)$/.test(keyword['matchedValue'])) {
        return 'day'
      }
    }
    return 'STRING'
  }

  calcAdviseXAxis () {
    const _dateTimes = []
    this.keywordList.forEach(item => {
      if (item['used'] && item['keywordCategory'] === 'DIMENSION_TIME') { // 时间维度
        if (!_dateTimes.includes(item['keywordOutputDirect'])) _dateTimes.push(item['keywordOutputDirect'])
      }
    })
    let _dateTimeName = ''
    if (_dateTimes.length > 0) {
      _dateTimeName = _dateTimes[0]
    }
    this.adviseDatetimeAxis = this.adviseXAxis = _dateTimeName
  }
}
