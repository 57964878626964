<template>
  <v-chart
    v-if="ready"
    class="pie-chart"
    :forceFit="true"
    :height="height"
    padding="auto"
    :data="dataSource1"
    :scale="scale"
    :theme="theme"
    :key="key">
    <v-tooltip :showTitle="false" :itemTpl="itemTpl"/>
    <!-- <v-legend :dataKey="xAxisName1"/> -->
    <v-stack-bar position="percent" :color="xAxisName1" :label="label1" :style="style" :tooltip="tooltip"/>
    <v-coord type="theta" :radius="0.7" :innerRadius="0.55"/>
  </v-chart>
</template>
<script>
import { debounce } from 'lodash'
import { simplifyNumber } from '@/utils/numberUtil'
import { v4 as uuidv4 } from 'uuid'
const DataSet = require('@antv/data-set')
export default {
  name: 'RingChart',
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    xAxis: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      ready: false,
      itemTpl: `<li>
        <div style="display: inline-block">
          {name} : {value}
        </div>
        <div style="display: inline-block">
          百分比 : {percent}
        </div>
      </li>`,
      dataSource1: [],
      xAxisName1: '',
      yAxisName1: '',
      label1: {},
      scale: [{
        dataKey: 'percent',
        min: 0,
        formatter: '.0%'
      }],
      style: {
        stroke: '#fff',
        lineWidth: 1
      },
      key: uuidv4()
    }
  },
  computed: {
    tooltip () {
      return [
        this.xAxisName1 + '*percent*' + this.yAxisName1, (item, percent, value) => {
          percent = (percent * 100).toFixed(2) + '%'
          return {
            name: item,
            percent: percent,
            value: simplifyNumber(value) + this.reportData.units[0].unit
          }
        }
      ]
    }
  },
  mounted() {
    let dvPie1 = null
    if (this.reportData['dimensions'].length === 0 && this.reportData['metrics'].length > 1) {
      // 无维度多指标
      this.xAxisName1 = '指标'
      this.yAxisName1 = '指标值'
      const source = []
      const dataItems0 = this.reportData['dataItems'][0]
      Object.keys(dataItems0).forEach(key => {
        source.push({
          '指标': key,
          '指标值': dataItems0[key]
        })
      })
      dvPie1 = new DataSet.View().source(source)
    } else {
      this.xAxisName1 = this.reportData.dimensions[0]
      this.yAxisName1 = this.reportData.metrics[0]
      dvPie1 = new DataSet.View().source(this.reportData.dataItems)
    }

    dvPie1.transform({
      type: 'percent',
      field: this.yAxisName1,
      dimension: this.xAxisName1,
      as: 'percent'
    })
    this.dataSource1 = dvPie1.rows
    this.label1 = ['percent', {
      formatter: (val, item) => {
        return simplifyNumber(item.point[this.yAxisName1]) + ' ' + this.reportData.units[0].unit
      }
    }]
    this.ready = true
    this.chartRenderedDelay()
  },
  watch: {
    theme () {
      this.key = uuidv4()
    }
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
  .pie-chart {
  }
</style>
